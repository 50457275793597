import React from 'react';
import { useNavigate } from 'react-router-dom';
import { useSelector,useDispatch } from 'react-redux';
import {
	CCard,
	CCardHeader,
	CCardBody,
	CRow,
	CCol,
	CLink,
	CTooltip,
	CBadge,
	CButton,
	CDropdown,
	CDropdownToggle,
	CDropdownMenu,
	CDropdownItem,
	CSpinner,
} from '@coreui/react';
import CIcon from '@coreui/icons-react';
import { 
	MyPage500,
	MyDialogview,
} from '../components/index';
import { UFunc } from "../helpers/functions";
import { Konfirm } from '../helpers/onConfirm';
import NumberFormat from 'react-number-format';
import { isMobile } from "react-device-detect";

let pjson     		= require('../../package.json');
const Kasirklinik 	= (props) => {
	const uComponentname 	= Kasirklinik.name;
	const { 
		setLoading,showToast,prosesExpired,setSessionaktif
	} = props; 

	let uFuncname;
	const uNavigate  		= useNavigate();
	const uDispatch			= useDispatch();
	const uTokenObj			= JSON.parse(localStorage.getItem("token") || "{}");
	const uBahasaObj  		= useSelector(state => state.listBahasa);

	const uActiveroute  	= useSelector(state => state.activeRoute);
	const uHTMLstyleTabel	= useSelector(state => state.gHTMLstyleLaporan);
	const uIsScrollBottom	= useSelector(state => state.gIsScrollBottom);
	const uIsEOMExecuted	= useSelector(state => state.gIsEOMExecuted);
	const uDefaultSettingObj= useSelector(state => state.gListDefaultSetting);
	const uUserSettingObj 	= useSelector(state => state.gListUserSetting);
	const uEfekapp			= useSelector(state => state.effectApp);
	const [uEfekview,setEfekview]	= React.useState(false);

	const uKeywordObj		= JSON.parse(localStorage.getItem("listkeyword") || "{}");
	const uHeaderActionObj  = useSelector(state => state.gInitHeaderAction);
	const uHandelView 		= uHeaderActionObj.isHeaderView || false;
	const uHandelTambah 	= uHeaderActionObj.isHeaderTambah || false;

	//--GLOBAL_VARS--/
	const uIsInitHapus		= (uTokenObj.userhak==="FREEROLE");
	const uMaxData			= isMobile ? (uUserSettingObj.jml_mobile || 20) : (uUserSettingObj.jml_laporan || 100);
	const [uHTMLError500,setHTMLError500]	= React.useState(null);

	const [uTabelRowSelect,setTabelRowSelect]= React.useState(-1);
	const [uTabelIDPilih,setTabelIDPilih]	= React.useState(0);
	const [uRefreshview,setRefreshview]		= React.useState(false);
	const [uJmlData,setJmlData]				= React.useState(0);
	const [uJmlHal,setJmlHal]				= React.useState(1);
	const [uPageActive,setPageActive]		= React.useState(1);
	const [uTimeElapsed,setTimeElapsed]		= React.useState(0);
	const [uDatamainArr,setDatamainArr]		= React.useState([]);

	//---FORMDIALOG--/
	const [uIsShowDlgview,setShowDlgview]		= React.useState(false);
	const [uuHeaderDlgview,setuHeaderDlgview]	= React.useState("");
	//---END FORMDIALOG--/

	const uElJmldata	= document.getElementById("idjmldata");
	//--END GLOBAL_VARS--/

	//---HANDLE--/
	const hdlToggleDlgview=()=>{setShowDlgview(false)}
	const hdlKlikBayar = (_IDX) =>{
		_IDX = parseInt(_IDX)||0; if(_IDX < 0) return;

		const vCustomerID	= uDatamainArr[_IDX].customer_id || 0;

		uNavigate("/kasir/kasirklinik/formbayar/"+vCustomerID);
	}
	const hdlKlikBatal=async(_IDX)=>{
		_IDX = parseInt(_IDX)||0;
		if(_IDX < 0) return;

		const vCaptionUpper = (uDatamainArr[_IDX].customer_caption || "").toUpperCase();
		setTabelRowSelect(_IDX)
		setTabelIDPilih(_IDX)

		const vConfirm	= 
			(uBahasaObj.caption_batalkankasir||"caption_batalkankasir")+": "+
			"<B>"+vCaptionUpper+"</B>"+
			"<BR>"+(uBahasaObj.petunjuk_hapus||"petunjuk_hapus")+
			"<BR><BR><B>"+(uBahasaObj.confirm_yakin||"confirm_yakin")+"</B>"+
		"";

		if(await Konfirm(vConfirm)) apiHapusdata(_IDX);
	}
	const hdlKlikPiutang = (_IDX) => {
		_IDX = parseInt(_IDX)||0; if(_IDX < 0) return;

		const vCustomer = uDatamainArr[_IDX].customer_caption || "";
		setTabelIDPilih(_IDX)

		//---CLEAR_KEYWORD--/
		uKeywordObj.kwd_kasirpiutang = {
			kwd_inputteks: "YA",
			kwd_value: vCustomer,
		}
		localStorage.setItem("listkeyword",JSON.stringify(uKeywordObj));
		//---END CLEAR_KEYWORD--/

		uNavigate("/kasirpiutang");
	}
	const hdlKlikDetil=(_IDX)=>{
		_IDX = parseInt(_IDX)||0;
		if(_IDX < 0) return;

		setTabelRowSelect(_IDX)
		setTabelIDPilih(_IDX)

		const vCaptionUpper = (uDatamainArr[_IDX].customer_caption || "").toUpperCase();
		setuHeaderDlgview((uBahasaObj.caption_lihatdetil)+": "+vCaptionUpper)

		apiLoaddetil(_IDX)
	}
	//---END HANDLE--/

	//---INIT_FUNCTION--/
	//---END INIT_FUNCTION--/

	//---Init_CONTENT--/
	const contentMain = () => {
		if(uHTMLError500) return "";

		const vMin			= (uPageActive-1)*uMaxData;
		const vMax			= (vMin+uMaxData);
		let vNumber			= (vMin);
		let vCaptionUpper	= "";
		const vKeyword 		= UFunc.filterRegex(uKeywordObj.kwd_kasirklinik || "");
		const vKeywordcolor = uUserSettingObj.color_keyword;
		const vReplace 		= new RegExp(vKeyword,"ig");

		const vDatamainArr	= uDatamainArr;
		return (
		<table className="table table-borderless table-striped table-hover" id="idtabeldata">
		<thead>
		<tr className="d-none d-md-table-row text-center align-top classfontsmaller">
		<th width="5%" className="px-1">No</th>
		<th className="px-1">
			<CRow className="mx-0">
			<CCol md="3" className="text-start">{uBahasaObj.word_customer||"word_customer"}</CCol>
			<CCol md="4" className="text-start">{uBahasaObj.word_keterangan||"word_keterangan"}</CCol>
			<CCol md="2" className="">
				{uBahasaObj.caption_nilaitransaksi||"caption_nilaitransaksi"}
				<small className="d-block">(Rp)</small>
			</CCol>
			<CCol md="1" className="">&middot;</CCol>
			<CCol md="2" className="">&nbsp;</CCol>
			</CRow>
		</th>
		</tr>

		<tr className="d-md-none">
		<th width={30} className="p-0"/>
		<th className="p-0"/>
		</tr>
		</thead>

		<tbody>
		{vDatamainArr.map((vItems,vKeys)=>{
			const {
				id,customer_id,customer_caption,customer_telp,customer_dp,
				ongoing_arr,isAdapiutang,isProcessing,
			} = vItems

			vNumber++;
			vCaptionUpper		= ((customer_caption||"")).toUpperCase();

			const vOngoingArr	= ongoing_arr || [];
			const vPasienArr 	= ongoing_arr.map(vItem1=>vItem1.pasien_caption);
			const vPasienlist 	= vPasienArr.join(", ");

			const vNilaiArr		= ongoing_arr.map(vItem1=>vItem1.nilai);
			const vNilaiTrx		= vNilaiArr.reduce((a,b)=>parseInt(a)+parseInt(b),0);

			const vCatatanlist 	= ongoing_arr.map((vItem1,vKeys)=>{
				return ((vItem1.catatan_kasir||"")!=="" ? (vKeys>0 ? "; " : "")+vItem1.catatan_kasir : "")
			}).join("");

			return (
			<tr id={"idtr"+vKeys} className={"align-top text-end"+((uTabelRowSelect===vKeys && !isMobile)?" classrowselect":"")} key={vKeys}>
			<td className="px-1 pe-0">{(vNumber)}.</td>

			<td className="px-1">
			<CRow className="mx-0">
				<CCol xs="12" md="3" className="px-1 text-start d-flex justify-content-between">
					<div>
					<span className="text-info text-capitalize">{customer_caption}</span>
					{(customer_telp||"").trim().length >= 3 && (
					<div className="classfontsmaller d-none d-md-block">
						<small>{uBahasaObj.caption_telphp}. <strong>{customer_telp}</strong></small>
					</div>
					)}
					{(isAdapiutang||false)===true && (
						<span className="d-md-block">
						<span className="mx-1 d-md-none">&middot;</span>
						<CLink className="classcursorpointer" onClick={()=>hdlKlikPiutang(vKeys)}>
						<CBadge color="danger" className="p-1 px-2 text-white classblinking">ADA PIUTANG !!</CBadge>
						</CLink>
						</span>
					)}
					</div>

					<div className="d-md-none">
					{(isProcessing||false)===true ? (
						<CSpinner size="sm" color="primary"/>
					) : (
					<CDropdown>
					<CDropdownToggle color="transparent" className="p-0" 
						caret={false}>
						<CIcon icon="cilOptions" className="classikoncoreui" height={25}/>
					</CDropdownToggle>
					<CDropdownMenu>
						<CDropdownItem 
							className="p-1 px-2 classcursorpointer"
							onClick={()=>hdlKlikDetil(vKeys)}>
							<CIcon className="classikondetil me-2 align-middle" height={25}/>
							<span>{uBahasaObj.caption_lihatdetil}</span>
						</CDropdownItem>
						{uIsInitHapus && (
						<CDropdownItem 
							className="p-1 px-2 classcursorpointer"
							onClick={()=>hdlKlikBatal(vKeys)}>
							<CIcon className="classikonhapus me-2 align-middle" height={25}/>
							<span>{uBahasaObj.caption_batalkankasir||"caption_batalkankasir"}</span>
						</CDropdownItem>
						)}
					</CDropdownMenu>
					</CDropdown>
					)}
					</div>
				</CCol>
				
				<CCol xs="12" className="px-1 my-3 d-md-none classborderbottom"/>

				<CCol md="4" className="px-1 text-md-start d-none d-md-block">
				<ul className="px-3 classfontsmaller">
					<li className="fst-italic"><b>{uBahasaObj.word_pasien}</b>: {vPasienlist}</li>
	
					<li>{(uBahasaObj.caption_titipandp||"caption_titipandp")}: <span className="text-success">Rp{UFunc.formatAngka(customer_dp)}</span></li>
	
					{(vCatatanlist||"")!=="" && (
						<li className="text-danger">{vCatatanlist}</li>
					)}
				</ul>
				</CCol>

				{(customer_telp||"").trim().length >= 3 && (
				<>
				<CCol xs="4" className="px-1 text-start d-md-none">{uBahasaObj.caption_telphp}</CCol>
				<CCol xs="8" className="px-1 d-md-none"><small className="fw-bold">{customer_telp||"-"}</small></CCol>
				</>
				)}

				{parseFloat(customer_dp) >= 0 && (
				<>
				<CCol xs="6" className="px-1 text-start d-md-none">{uBahasaObj.caption_titipandp} (Rp)</CCol>
				<CCol xs="6" className="px-1 d-md-none text-success">{UFunc.formatAngka(customer_dp)}</CCol>
				</>
				)}
				
				<CCol xs="3" className="px-1 text-start d-md-none">{uBahasaObj.word_pasien}</CCol>
				<CCol xs="9" className="px-1 d-md-none">
					<small className="">
					{vPasienlist}
					</small>
				</CCol>

				{(vCatatanlist||"").trim() !== "" && (
				<>
				<CCol xs="3" className="px-1 text-start d-md-none">{uBahasaObj.word_catatan||"word_catatan"}</CCol>
				<CCol xs="9" className="px-1 d-md-none">
					<small className="fst-italic text-danger">{vCatatanlist||"-"}</small>
				</CCol>
				</>
				)}
				
				<CCol xs="12" className="px-1 my-3 d-md-none classborderbottom"/>

				<CCol xs="5" className="px-1 text-start d-md-none">{uBahasaObj.caption_nilaitransaksi||"Nilai Transaksi"} (Rp)</CCol>
				<CCol xs="7" md="2" className="px-1 text-success fw-bold">{UFunc.formatAngka(vNilaiTrx)}</CCol>
				
				<CCol md="1" className="px-md-0 d-none d-md-block text-md-center">
					{(isProcessing||false)===true ? (
						<CSpinner size="sm" color="primary"/>
					) : (
					<CDropdown>
					<CDropdownToggle color="transparent" className="p-0 m-0" 
						caret={false}>
						<CIcon icon="cilChevronBottom" className="classikoncoreui" height={25}/>
					</CDropdownToggle>
					<CDropdownMenu>
						<CDropdownItem 
							className="p-1 px-2 classcursorpointer"
							onClick={()=>hdlKlikDetil(vKeys)}>
							<CIcon className="classikondetil me-2 align-middle" height={25}/>
							<span>{uBahasaObj.caption_lihatdetil}</span>
						</CDropdownItem>
						{uIsInitHapus && (
						<CDropdownItem 
							className="p-1 px-2 classcursorpointer"
							onClick={()=>hdlKlikBatal(vKeys)}>
							<CIcon className="classikonhapus me-2 align-middle" height={25}/>
							<span>{uBahasaObj.caption_batalkankasir||"caption_batalkankasir"}</span>
						</CDropdownItem>
						)}
					</CDropdownMenu>
					</CDropdown>
					)}
				</CCol>
				
				{uIsEOMExecuted && (
				<CCol xs="12" className="px-1 my-3 d-md-none classborderbottom"/>
				)}

				<CCol md="2" className="px-1 text-md-center">
				{uIsEOMExecuted ? (
				<CButton size="sm"
					color="primary"
					className="px-3"
					onClick={()=>hdlKlikBayar(vKeys)}
					id="btnbayar">
					{uBahasaObj.word_bayar}
					<CIcon icon="cilArrowRight" className="ms-2 align-bottom"/>
				</CButton>
				) : (
					<span className="d-none d-md-block">&middot;</span>
				)}
				</CCol>
			</CRow>
			</td>
			</tr>
			)
		})}
		</tbody>
		</table>
		)//>
	}
	const contentDlgview = () => {
		if(!uIsShowDlgview) return (<></>)//>

		const vObjidx = parseInt(uTabelIDPilih);
		if(vObjidx < 0) return (<></>);

		const vItemsArr	= uDatamainArr[vObjidx].items_arr || [];
		if(vItemsArr.length <= 0) return (<></>)//>
		
		//console.log("("+uComponentname+" - contentDlgview) vObjidx : "+vObjidx);
		return (
		<CCard>
		<CCardBody className="px-0 classfontsmaller">
		{vItemsArr.map((vItems,vKeys)=>{
			const {
				id,pasien_caption,item_caption,item_qty,item_satuan,item_harga
			} = vItems

			return (
			<CRow className="mx-0 py-2 classborderbottom" key={vKeys}>
			<CCol xs="1" className="px-1 text-end">{vKeys+1}.</CCol>
			<CCol xs="11" md="3" className="px-1 text-primary">{pasien_caption}</CCol>
			<CCol xs="1" className="px-1 d-md-none"/>
			<CCol xs="9" md="6" className="px-1">
				{item_caption||"UNDF"} <span className="text-success">(@Rp{UFunc.formatAngka(item_harga)})</span>
			</CCol>
			<CCol xs="2" className="px-1 text-center">{UFunc.formatAngka(item_qty)} <b>{item_satuan||"UNT"}</b></CCol>
			</CRow>
			)
		})}
		</CCardBody>
		</CCard>
		)//>
	}
	//---END Init_CONTENT--/

	//---API_FUNC--/
	const apiLoaddata = () => {
		uFuncname = apiLoaddata.name;
		setHTMLError500();

		const vKeyword = uKeywordObj.kwd_kasirklinik || "";

		setJmlData(0);
		setDatamainArr([]);
		setPageActive(1);
		setTabelIDPilih(0);
		setTabelRowSelect(-1);
		setLoading(true);

		/*//---TESTING_FRONTEND--/
		let vTimeout	= setTimeout(() => {
			clearTimeout(vTimeout);
		},3500); return
		//---END TESTING_FRONTEND--*/

		const vDATAS	= JSON.stringify({
			send_tokenauth : uTokenObj.userinit
		});
		const vURLs	= pjson.homepage+"api/api_kasirklinik/ld_data";

		setTimeElapsed(0);
		const vTimeBegin = new Date();
		fetch(vURLs,{
			headers: { Accept: "application/json", "Content-Type": "application/json" },
			method: "POST",
			mode: "cors",
			cache: "default",
			body: vDATAS,
		}).then((response)=> { if (response.status === 200){setSessionaktif(false);return response.json(); }})
		.then((output_string) => {
			setSessionaktif(true);
			setLoading(false);
			//console.log("("+uComponentname+"-"+uFuncname+") output_string : "+JSON.stringify(output_string.errors));
			if(output_string.tampil) {
				const vDatamainArr = JSON.parse(output_string.dataobject || "[]");
				setDatamainArr(vDatamainArr);
				setJmlData(vDatamainArr.length);

				const vTimeEnd = new Date();
				const vTimeDiff = vTimeEnd - vTimeBegin; //in ms
				setTimeElapsed(vTimeDiff);
			} else if(output_string.info) {
				setHTMLError500(output_string.info);
			} else if(output_string.errors) {
				console.log("("+uComponentname+"-"+uFuncname+") output_string.errors : "+output_string.errors);
				const vMsg = (pjson.mydefault.environment==="development")
					? output_string.errors : pjson.mydefault.msgFetchError;
				setHTMLError500(vMsg,"ERROR");
			} else if(output_string.expired) {
				setLoading(true);
				prosesExpired(uNavigate);
			}
		})
		.catch((error) =>{
			setLoading(false);
			console.log("("+uComponentname+"-"+uFuncname+") catch-error: "+error);
			setHTMLError500(pjson.mydefault.msg500str.join(" "));
		});
	}
	const apiHapusdata = (_IDX) => {
		uFuncname	= apiHapusdata.name;
		_IDX = parseInt(_IDX)||0;
		if(_IDX < 0) return;

		const vOngoingArr 	= uDatamainArr[_IDX].ongoing_arr||[];
		const vOngoingIDarr	= vOngoingArr.map(vItems=>vItems.ongoing_id);
		uDatamainArr[_IDX].isProcessing = true;
		setRefreshview(!uRefreshview);

		/*//---TESTING_FRONTEND--/
		console.log("("+uComponentname+"-"+uFuncname+") uTabelIDPilih : "+uTabelIDPilih);
		let vTimeout = setTimeout(()=>{
			clearTimeout(vTimeout)
		},2000); return
		//---END TESTING_FRONTEND--*/

		const vDATAS	= JSON.stringify({
			send_customerid : uDatamainArr[_IDX].customer_id,
			send_ongoinglist : vOngoingIDarr.join(","),
			send_tokenauth : uTokenObj.userinit
		});
		const vURLs	= pjson.homepage+"api/api_kasirklinik/pr_batal";

		fetch(vURLs,{
			headers: { Accept: "application/json", "Content-Type": "application/json" },
			method: "POST",
			mode: "cors",
			cache: "default",
			body: vDATAS,
		}).then((response)=> { if (response.status === 200){setSessionaktif(false);return response.json(); }})
		.then((output_string) => {
			setSessionaktif(true);
			uDatamainArr[_IDX].isProcessing = false;

			if(output_string.sukses) {
				uDatamainArr.splice(_IDX,1)
				setJmlData((parseInt(uDatamainArr.length)||0))

				setTabelRowSelect(-1)
				setTabelIDPilih(0)
				setRefreshview(!uRefreshview)
			} else {
				setRefreshview(!uRefreshview)
				if(output_string.info) {
					showToast(output_string.info);
				} else if(output_string.errors) {
					console.log("("+uComponentname+"-"+uFuncname+") output_string.errors : "+output_string.errors);
					const vMsg = (pjson.mydefault.environment==="development")
						? output_string.errors : pjson.mydefault.msgFetchError;
					showToast(vMsg,"ERROR");
				} else if(output_string.expired) {
					setLoading(true); prosesExpired();
				}
			}
		})
		.catch((error) =>{
			uDatamainArr[_IDX].isProcessing = false
			setRefreshview(!uRefreshview)

			setLoading(false)
			console.log("("+uComponentname+"-"+uFuncname+") catch-error: "+error);
			showToast(pjson.mydefault.msg500str.join(" "),"ERROR");
		});
	}
	const apiLoaddetil= (_IDX) => {
		uFuncname	= apiLoaddetil.name;
		_IDX = parseInt(_IDX)||0;
		if(_IDX < 0) return;

		const vObjidx		= _IDX;
		const vItemsArr		= uDatamainArr[vObjidx].items_arr || [];
		const vOngoingArr	= uDatamainArr[vObjidx].ongoing_arr || [];
		const vOngoingIDarr	= vOngoingArr.map(a => a.ongoing_id);

		if(vItemsArr.length > 0) {
			setShowDlgview(true)
			return
		}

		uDatamainArr[vObjidx].isProcessing = true;
		setRefreshview(!uRefreshview)

		/*//---TESTING_FRONTEND--/
		console.log("("+uComponentname+"-"+uFuncname+") vOngoingIDarr : "+vOngoingIDarr.join(";"));

		let vTimeout = setTimeout(()=>{
			clearTimeout(vTimeout)
			setShowDlgview(true)
		},2000); return
		//---END TESTING_FRONTEND--*/

		const vDATAS	= JSON.stringify({
			send_ongoinglist : vOngoingIDarr.join(","),
			send_tokenauth : uTokenObj.userinit
		});
		const vURLs	= pjson.homepage+"api/api_kasirklinik/ld_detil";

		fetch(vURLs,{
			headers: { Accept: "application/json", "Content-Type": "application/json" },
			method: "POST",
			mode: "cors",
			cache: "default",
			body: vDATAS,
		}).then((response)=> { if (response.status === 200){setSessionaktif(false);return response.json(); }})
		.then((output_string) => {
			setSessionaktif(true);
			uDatamainArr[vObjidx].isProcessing = false;
			setRefreshview(!uRefreshview)
			if(output_string.tampil) {
				//console.log("("+uComponentname+"-"+uFuncname+") output_string.itemslist : "+output_string.itemslist);
				uDatamainArr[vObjidx].items_arr = JSON.parse(output_string.itemslist||"[]");
				setShowDlgview(true)
			} else if(output_string.info) {
				showToast(output_string.info);
			} else if(output_string.errors) {
				console.log("("+uComponentname+"-"+uFuncname+") output_string.errors : "+output_string.errors);
				const vMsg = (pjson.mydefault.environment==="development")
					? output_string.errors : pjson.mydefault.msgFetchError;
				showToast(vMsg,"ERROR");
			} else if(output_string.expired) {
				setLoading(true); prosesExpired();
			}
		})
		.catch((error) =>{
			uDatamainArr[vObjidx].isProcessing = false
			setRefreshview(!uRefreshview)

			setLoading(false)
			console.log("("+uComponentname+"-"+uFuncname+") catch-error: "+error);
			showToast(pjson.mydefault.msg500str.join(" "),"ERROR");
		});
	}
	//---END API_FUNC--/

	//---EFFECT--/
	React.useEffect(()=>{});//-->EFFECT_DIULANG_TERUS_TIAP_ONCHANGE_DI_PAGE--/
	React.useEffect(()=>{
		uDispatch({type: "set", effectApp: !uEfekapp});
		uDispatch({type: "set", gUrlbefore: window.location.pathname + window.location.search});

    	apiLoaddata();

    	return ()=>{
			setShowDlgview(false);
    		setDatamainArr([]);
    	}
	},[]);
	React.useEffect(()=>{
		uElJmldata && 
			(uElJmldata.innerHTML = UFunc.formatAngka(uJmlData));
	},[uElJmldata]);
	React.useEffect(()=>{
		let vJmlHal	= Math.ceil(uJmlData / uMaxData);
		setJmlHal(vJmlHal);//-*/
		setPageActive(uPageActive<=0?1:uPageActive);

		uElJmldata && (uElJmldata.innerHTML = UFunc.formatAngka(uJmlData));
	},[uJmlData]);
	React.useEffect(()=>{ setTabelRowSelect(-1); },[uPageActive]);//-->Unselect_ROW
	React.useEffect(()=>{
		if(!uHandelView) return;

		uHeaderActionObj.isHeaderView = false;
		uDispatch({type: "set", gInitHeaderAction: uHeaderActionObj});

		apiLoaddata();
	},[uHandelView]);
	React.useEffect(()=>{ if(!uIsShowDlgview) setTabelIDPilih(0) },[uIsShowDlgview]);
	//---END EFFECT--/

	//console.log("("+uComponentname+") Checked..");//---KALO_GA_ADA_YG_PAKE_cComponentName
	if(UFunc.isEmpty(uTokenObj)) { prosesExpired(); return (<></>); }
	if(uHTMLError500) return (
		<MyPage500 content={uHTMLError500} {...props}/>
	)//>

	return (
		<>
		<CCard className="classcardbg">
			<CCardHeader className="d-flex justify-content-between align-items-center">
			<div/>
			<span className="text-muted classfontsmaller">
				<small>{UFunc.formatAngka(uTimeElapsed)}ms</small>
			</span>
			</CCardHeader>

			<CCardBody className="px-0">{contentMain()}</CCardBody>
		</CCard>

		<MyDialogview
			options={{size:"lg",centered:false}}
			show={uIsShowDlgview} 
			dialogHeader={uuHeaderDlgview}
			toggle={hdlToggleDlgview}
			renderContent={contentDlgview()}/>
		</>
	)//>
}
	
export default Kasirklinik;
