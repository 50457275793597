import React from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import {
	CHeader,
	CHeaderNav,
	CHeaderBrand,
	CHeaderToggler,
	CContainer,
	CCard,
	CCardHeader,
	CCardBody,
	CCardFooter,
	CRow,
	CCol,
	CForm,
	CFormInput,
	CFormSelect,
	CInputGroup,
	CInputGroupText,
	CLink,
	CTooltip,
	CButton,
	CImage,
} from '@coreui/react';
import CIcon from '@coreui/icons-react';
import { 
	MyPage500,
	MyPagination,
	MyButton,
	MyDialoglookup,
	MyDialoglookupapi,
	MyProfileDetil,
} from '../../components/index';
import { UFunc } from "../../helpers/functions";
import { Konfirm } from '../../helpers/onConfirm';
import NumberFormat from 'react-number-format';
import { isMobile } from "react-device-detect";

const pjson 		= require('../../../package.json')
const Titipandpmain = (props) => {
	const uComponentname 	= Titipandpmain.name;

	const {
		setLoading,showToast,prosesExpired,setSessionaktif
	} = props; 

	const { id_customer } 	= useParams(); 

	let uFuncname;
	const uNavigate  		= useNavigate();
	const uDispatch			= useDispatch();
	const uTokenObj			= JSON.parse(localStorage.getItem("token"));

	const uBahasaObj  		= useSelector(state => state.listBahasa);
	const uActiveroute  	= useSelector(state => state.activeRoute);
	const uHTMLstyleTabel	= useSelector(state => state.gHTMLstyleLaporan);
	const uIsScrollBottom	= useSelector(state => state.gIsScrollBottom);
	const uIsEOMExecuted	= useSelector(state => state.gIsEOMExecuted);
	const uDefaultSettingObj= useSelector(state => state.gListDefaultSetting);
	const uUserSettingObj 	= useSelector(state => state.gListUserSetting);
	const uEfekapp			= useSelector(state => state.effectApp);
	const [uEfekview,setEfekview] 	= React.useState(false);

	//--GLOBAL_VARS--/
	const uCustomerID 		= parseInt(id_customer)||0;
	const [uHTMLError500,setHTMLError500]			= React.useState(null);

	//---LOOKUPAPI--/
	const [uCustomerArr,setCustomerArr]				= React.useState([]);
	const [uISDlglookupapishow,setDlglookupapishow]	= React.useState(false);
	const [uISDlglookupapitrigger,setDlglookupapitrigger]= React.useState(false);
	const [uDlglookupapidataObj,setDlglookupapidataObj]= React.useState({});
	//---END LOOKUPAPI--/

	//---LOOKUP--/
	const [uPaymentArr,setPaymentArr]				= React.useState([]);
	const [uIsLookupShow,setLookupShow]				= React.useState(false);
	const [uDialogHeader,setDialogHeader]			= React.useState("");
	const [uInpLookupKwd,setInpLookupKwd]			= React.useState("");
	const [uLookupDataArr,setLookupDataArr]			= React.useState([]);
	const [uLookupjenis,setLookupjenis]				= React.useState("");
	//---END LOOKUP--/

	//---FORMFIELD--/
	const [uParameterObj,setParameterObj]			= React.useState({});
	const [uIsFormprocess,setFormprocess]			= React.useState(false);
	const [uISFormupdated,setFormupdated]			= React.useState(false);
	const ElBtnSimpan								= document.getElementById("btnSimpan");
	//---END FORMFIELD--/
	//--END GLOBAL_VARS--/

	//---HANDLE--/
	const hdlKlikBack = async() => {
		const vNextprocess = () => uNavigate(-1);

		if(!uISFormupdated) {
			vNextprocess(); return;
		}

		if(await Konfirm("<b>Form Telah Mengalami Perubahan</b>.."+
			"<br/>Data Belum Disimpan, dan Tidak Akan Dapat Dikembalikan."+
			"<br/><br/>Apakah Tetap Akan Kembali?"+
			"")) vNextprocess();
	}
	const hdlKlikReset = async() => {
		const vNextprocess = () => {
			if(uCustomerID <= 0) 
				{ setParameterObj({});  return; }

			const vArridx	= UFunc.getObjectIndeks(uCustomerArr,uCustomerID);
			setParameterObj({
				customer_id : ((uCustomerArr[vArridx]||{}).id || 0),
				customer_caption : ((uCustomerArr[vArridx]||{}).caption || "")
			});
		}

		if(!uISFormupdated) {
			apiInitawal(); return;
		}

		//console.log("(Titipandpmain - hdlKlikReset) uParameterObj = "+JSON.stringify(uParameterObj));

		if(await Konfirm("<b>Form Telah Mengalami Perubahan</b>.."+
			"<br/>Data Belum Disimpan, dan Tidak Akan Dapat Dikembalikan."+
			"<br/><br/>Apakah Tetap Akan MERESET ?"+
			"")) vNextprocess();
	}
	const hdlToggleLookup = () => {setLookupShow(false)}
	const hdlKlikPilihLookupapi = (_SELECTEDSTR) => { initLookuppilihcustomer(_SELECTEDSTR); }
	const hdlKlikPilihLookup = (_IDITEM) => {
		if(uLookupjenis==="PAYMENT") {
			initLookuppilihpayment(_IDITEM); return;
		}
	}
	const hdlKlikLookupcustomer = () => {
		uDlglookupapidataObj.keyword	= uParameterObj.customer_caption;
		uDlglookupapidataObj.header 	= (uBahasaObj.word_lookup||"word_lookup")+": "+
			(uBahasaObj.word_customer||"word_customer");
		setDlglookupapishow(true);
	}
	const hdlKeyDownCustomer = (_EV) => {
		uParameterObj.customer_id = ("0");
		if(_EV.keyCode !== 13) return;
		if(uParameterObj.customer_caption.trim()==="") { return; }

		_EV.preventDefault();
		_EV.stopPropagation();

		setDlglookupapitrigger(true)
		hdlKlikLookupcustomer();
	}
	const hdlKlikLookuppayment=()=>{
		const vTmpArr	= uPaymentArr.map(vItems=>{
			return {id:vItems.id,caption:vItems.caption}
		})
		setInpLookupKwd("");
		setLookupjenis("PAYMENT");
		setLookupDataArr(vTmpArr);
		setDialogHeader((uBahasaObj.word_pilih||"word_pilih")+": "+(uBahasaObj.caption_paymentmethod||"caption_paymentmethod").toUpperCase());
		setLookupShow(true);
	}
	const hdlKlikSimpan=()=>{
		//alert(parseInt(uParameterObj.nilai||0)); return;
		if(uIsFormprocess) return;

		//---VALIDASI--/
		if(parseInt(uParameterObj.customer_id||0) <= 0) {
			document.getElementById("inpcustomer").focus();
			showToast((uBahasaObj.word_customer||"word_customer")+" "+
				(uBahasaObj.caption_mustchoose||"caption_mustchoose")+"..");
			return;
		}
		if((parseInt(uParameterObj.nilai||0)) <= 0) {
			document.getElementById("inpnilaidp").focus();
			showToast((uBahasaObj.caption_nilaidp||"caption_nilaidp")+" "+
				(uBahasaObj.caption_invalid||"caption_invalid")+"..");
			return;
		}
		//---END VALIDASI--/

		apiProsessimpan();
	}
	const hdlKeydownSimpan=(_EV)=>{
		if(_EV.keyCode === 120) {
			_EV.preventDefault();
			_EV.stopPropagation();
			hdlKlikSimpan();
		}
	}
	//---END HANDLE--/

	//---INIT_PROCESS--/
	const initFormawal=()=>{
		setParameterObj({});
	}
	const initLookuppilihcustomer = (_SELECTEDSTR) => {
		//console.log("(Titipandpmain - initLookuppilihcustomer) _SELECTEDSTR => "+_SELECTEDSTR);
		//const vIdx = UFunc.getObjectIndeks(uLookupDataArr,_IDITEM,"id"); if(vIdx < 0) return;
		const vCustomerObj	= JSON.parse(_SELECTEDSTR || "{}");
		if(UFunc.isEmpty(vCustomerObj)) return;

		uParameterObj.customer_id 		= (vCustomerObj.id);
		uParameterObj.customer_caption 	= (vCustomerObj.caption);

		setDlglookupapishow(false);

		let vTimeout = setTimeout(function(){
			clearTimeout(vTimeout);
			const vElsFocus	= document.getElementById("inpnilaidp"); 
			if(!isMobile)
				vElsFocus && vElsFocus.focus();
		},350);
	}
	const initLookuppilihpayment=(_IDITEM)=>{
		const vIdx = UFunc.getObjectIndeks(uLookupDataArr,_IDITEM,"id");
		if(vIdx < 0) return;

		const vCaptiontmp			= uLookupDataArr[vIdx].caption||"";
		uParameterObj.payment_id 	= (uLookupDataArr[vIdx].id||"0");
		uParameterObj.payment_caption = (vCaptiontmp);

		hdlToggleLookup();

		let vTimeout = setTimeout(function(){
			clearTimeout(vTimeout);

			const vElsFocus	= vCaptiontmp.toUpperCase()==="TUNAI" 
				? document.getElementById("inpketerangan")
				: document.getElementById("inppaymentket");

			vElsFocus && vElsFocus.focus();
		},350);
	}
	//---END INIT_PROCESS--/

	//---CONTENT--/
	const contentMain = () => {
		if(uHTMLError500) return ""

		return (
		<CForm className="px-lg-5">

		{uTokenObj.userhak === "FREEROLE" && (
		<>
		<CRow className="my-2">
			<CCol xs="4" className="text-start text-md-end text-info">
				{uBahasaObj.caption_statustembakjurnal||"Status Jurnal"}
			</CCol>
			<CCol xs="8" lg="4">
				<CFormSelect size="sm"
						onChange={(e)=>{uParameterObj.status_tembak = (e.target.value);setEfekview(!uEfekview);}}
						value={uParameterObj.status_tembak}
						id="inpstatustembak">
					<option value="REGULER">Input REGULER</option>
					<option value="TEMBAK">Input TEMBAK DATA</option>
				</CFormSelect>

				<div className="classpetunjuk">
					<small>*) Jika TEMBAK DATA, Anda Harus Menginput JURNAL SECARA MANUAL</small>
				</div>
			</CCol>
		</CRow>

		<div className="mx-0 my-3 classborderbottom"/>
		</>
		)}

		<CRow className="my-2">
			<CCol xs="4" className="text-md-end text-info">{uBahasaObj.caption_tgltitipdp||"caption_tgltitipdp"}</CCol>
			<CCol xs="8" md="5" lg="4" className="">
			<CFormInput size="sm"
				type="date"
				value={uParameterObj.tgl_proses}
				onChange={(e)=>{uParameterObj.tgl_proses = (e.target.value);setEfekview(!uEfekview);}}
				id="inptgldp"/>
			</CCol>
		</CRow>

		<CRow className="my-2">
			<CCol xs="4" className="text-md-end text-info">{uBahasaObj.word_customer||"word_customer"}</CCol>
			<CCol xs="8" md="5" lg="4">
			<CInputGroup size="sm">
			<CFormInput size="sm"
				maxLength={100}
				value={uParameterObj.customer_caption||""}
				onKeyDown={(e)=>hdlKeyDownCustomer(e)}
				onChange={(e)=>{uParameterObj.customer_caption = (e.target.value);setEfekview(!uEfekview);}}
				id="inpcustomer"/>
				{uCustomerID <= 0 && (
				<CButton size="sm" color="dark"
					onClick={()=>hdlKlikLookupcustomer()} 
					id="btncustomerlookup">
				<CIcon icon="cilMagnifyingGlass"/>
				</CButton>
				)}
			</CInputGroup>
			{parseInt(uParameterObj.customer_id) > 0 && (
			<div className="border mt-1 py-1 rounded-lg border-warning classborderdotted classpetunjuk">
			{uCustomerArr.filter(vItemx => parseInt(vItemx.id)===parseInt(uParameterObj.customer_id)).map((vItems,vKeys) => {
				//console.log("vItems.id = "+vItems.id+" === vItems.caption="+vItems.caption);
				return (
					<div key={vKeys}>
						<div>{UFunc.renderHTML(vItems.alamat)}</div>
						<div>{uBahasaObj.caption_telphp}: <strong>{vItems.telp}</strong></div>
						{parseInt(vItems.saldo_dp||0) > 0 && (
						<div>{uBahasaObj.caption_saldodp}:{" "}
							<strong className="text-success">Rp {UFunc.formatAngka(vItems.saldo_dp)}</strong>
						</div>
						)}
					</div>
				)
			})}
			</div>
			)}
			</CCol>
		</CRow>

		<div className="my-3 classborderbottom"/>

		<CRow className="my-2">
			<CCol xs="4" className="text-md-end text-info">{uBahasaObj.caption_nilaidp||"caption_nilaidp"}</CCol>
			<CCol xs="8" md="5" lg="4" className="">
				<NumberFormat 
					value={uParameterObj.nilai}
					displayType={'text'} 
					thousandSeparator={"."} 
					decimalSeparator={","}
					onValueChange={(values) => uParameterObj.nilai = (values.value)}
					renderText={values => (
						<CFormInput 
							size="sm"
							value={values}
							className="text-end"
							onChange={(e)=>{uParameterObj.nilai = (e.target.value);setEfekview(!uEfekview);}}
							onFocus={(e)=>e.target.select()}
						id="inpnilaidp"/>
					)} 
				prefix={isMobile?"":"Rp"}/>
			</CCol>
		</CRow>

		<CRow className="my-2">
			<CCol xs="4" className="text-md-end text-info">
				<span className="classfontsmaller d-md-none"><small>{uBahasaObj.caption_paymentmethod||"caption_paymentmethod"}</small></span>
				<span className="d-none d-md-block">{uBahasaObj.caption_paymentmethod||"caption_paymentmethod"}</span>
			</CCol>
			<CCol xs="8" md="5" lg="4" className="">
			<CInputGroup size="sm">
			<CFormInput size="sm"
				placeholder={"--"+(uBahasaObj.word_pilih)+" "+(uBahasaObj.caption_paymentmethod)+".."}
				readOnly={true}
				className="classbgdisabled"
				value={uParameterObj.payment_caption||""}
				onClick={()=>hdlKlikLookuppayment()} 
				id="inppaymentcaption"/>
			<CButton size="sm"
					onClick={()=>hdlKlikLookuppayment()} 
					color="dark"
					id="btnpaymentlookup">
				<CIcon icon="cilMagnifyingGlass"/>
			</CButton>
			</CInputGroup>
			</CCol>
		</CRow>

		{((uParameterObj.payment_caption||"") !== "" && (uParameterObj.payment_caption||"").toUpperCase()!=="TUNAI"
		) && (
		<CRow className="my-2">
			<CCol md="4" className="text-md-end d-none d-md-block">&nbsp;</CCol>
			<CCol className="">
			<CFormInput size="sm"
				maxLength={255}
				placeholder={"--"+(uBahasaObj.caption_ketnontunai||"caption_ketnontunai")}
				value={uParameterObj.payment_ket||""}
				onChange={(e)=>{uParameterObj.payment_ket = (e.target.value);setEfekview(!uEfekview);}}
				id="inppaymentket"/>
			</CCol>
		</CRow>
		)}

		<div className="my-3 classborderbottom"/>

		<CRow className="my-2">
			<CCol md="4" className="text-md-end">{uBahasaObj.word_keterangan||"word_keterangan"}</CCol>
			<CCol md="8" className="">
			<CFormInput size="sm"
				maxLength={255}
				value={uParameterObj.keterangan||""}
				onChange={(e)=>{uParameterObj.keterangan = (e.target.value);setEfekview(!uEfekview);}}
				onKeyDown={(e)=>UFunc.hdlKeyDownInput(e,"btnSimpan")}
				id="inpketerangan"/>
			</CCol>
		</CRow>
		</CForm>
		)//->
	}
	//---END CONTENT--/

	//---REST_API--/
	const apiInitawal = () => {
		setHTMLError500();

		initFormawal();
		setCustomerArr([]);
		setPaymentArr([]);
		setLoading(true);
		setFormprocess(true);

		/*//--TESTING_FRONTEND--/
		console.log("(Titipandpmain-apiInitawal) LEWAT ");
		return;
		//--END TESTING_FRONTEND--*/

		const vDATAS	= JSON.stringify({
			send_tokenauth : uTokenObj.userinit
		});
		
		const vURLs	= pjson.homepage+"api/api_titipandp/ld_init";

		fetch(vURLs,{
			headers: { Accept: "application/json", "Content-Type": "application/json" },
			method: "POST",
			mode: "cors",
			cache: "default",
			body: vDATAS,
		}).then((response)=> { if(response.status === 200){ setSessionaktif(false); return response.json(); } })
		.then((output_string) => {
			setSessionaktif(true);
			setLoading(false);
			//console.log("(Titipandpmain-apiInitawal) output_string : "+JSON.stringify(output_string.errors));
			if(output_string.tampil) {
				setFormprocess(false);
				setCustomerArr(JSON.parse(output_string.dataobject||"[]"));
				setPaymentArr(JSON.parse(output_string.paymentlist||"[]"));

				if(!isMobile)
					document.getElementById("inpcustomer").focus();
			} else if(output_string.info) {
				setHTMLError500(output_string.info);
			} else if(output_string.errors) {
				console.log("(Titipandpmain-apiInitawal) output_string.errors : "+output_string.errors);
				const vMsg = (pjson.mydefault.environment==="development")
					? output_string.errors : pjson.mydefault.msgFetchError;
				setHTMLError500("<div class='text-danger'>"+vMsg+"</div>");
			} else if(output_string.expired) {
				prosesExpired();
			}
		})
		.catch((error) =>{
			setLoading(false);
			console.log("(Titipandpmain-apiInitawal) catch-error: "+error);
			setHTMLError500(pjson.mydefault.msg500str.join(" "));
		});
	}
	const apiProsessimpan = () => {
		/*//--TESTING_FRONTEND--/
		console.log("(Titipandpmain-apiProsessimpan) LEWAT ");
		return;
		//--END TESTING_FRONTEND--*/

		const vDATAS	= JSON.stringify({
			send_statusjurnal: uParameterObj.status_tembak,
			send_tgldp 		: uParameterObj.tgl_proses,
			send_customerid : uParameterObj.customer_id,
			send_customercaption : uParameterObj.customer_caption,
			send_nilaidp 	: uParameterObj.nilai,
			send_paymentid 	: uParameterObj.payment_id,
			send_paymentket	: uParameterObj.payment_ket,
			send_keterangan : uParameterObj.keterangan,
			send_tokenauth 	: uTokenObj.userinit
		});
		const vURLs	= pjson.homepage+"api/api_titipandp/pr_simpan";

		setLoading(true);
		setFormprocess(true);
		fetch(vURLs,{
			headers: { Accept: "application/json", "Content-Type": "application/json" },
			method: "POST",
			mode: "cors",
			cache: "default",
			body: vDATAS,
		}).then((response)=> { if(response.status === 200){ setSessionaktif(false); return response.json(); } })
		.then((output_string) => {
			setSessionaktif(true);
			setLoading(false);
			setFormprocess(false);
			if(output_string.sukses) {
				initFormawal();

				if(output_string.pesankhusus)
					showToast((output_string.pesankhusus||"pesan_sukses")+"..","SUKSES");
				else
					showToast((uBahasaObj.pesan_sukses||"pesan_sukses")+"..","SUKSES");

				if(uCustomerID > 0)
					{ uNavigate(-1); return }
				
				if(!isMobile) document.getElementById("inpcustomer").focus();
			} else if(output_string.info) {
				showToast(output_string.info);
			} else if(output_string.errors) {
				console.log("(Titipandpmain-apiProsessimpan) output_string.errors : "+output_string.errors);
				const vMsg = (pjson.mydefault.environment==="development")
					? output_string.errors : pjson.mydefault.msgFetchError;
				showToast(vMsg,"ERROR");
			} else if(output_string.expired) {
				prosesExpired();
			}
		})
		.catch((error) =>{
			setFormprocess(false);
			setLoading(false);
			console.log("(Titipandpmain-apiProsessimpan) catch-error: "+error);
			showToast(pjson.mydefault.msg500str.join(" "),"ERROR");
		});
	}
	//---END REST_API--/

	//---EFFECT--/
	React.useEffect(()=>{
		document.addEventListener("keydown",hdlKeydownSimpan);

		//return function cleanup() {
		return ()=>{
			document.removeEventListener("keydown",hdlKeydownSimpan);
		}
	});
	React.useEffect(()=>{
		uDispatch({type: "set", effectApp: !uEfekapp});
		uDispatch({type: "set", gUrlbefore: window.location.pathname + window.location.search});

		apiInitawal();

		return ()=>{ 
			hdlToggleLookup(); setDlglookupapishow(false);
			setCustomerArr([]); setPaymentArr([]);
		}
	},[]);
	React.useEffect(()=>{
		if(uCustomerID <= 0) return;

		const vArridx	= UFunc.getObjectIndeks(uCustomerArr,uCustomerID);
		setParameterObj({
			...uParameterObj,
			customer_id : ((uCustomerArr[vArridx]||{}).id || 0),
			customer_caption : ((uCustomerArr[vArridx]||{}).caption || "")
		});

		document.getElementById("inpcustomer") && (
			document.getElementById("inpcustomer").disabled = true);
	},[uCustomerArr,uCustomerArr.length]);
	React.useEffect(()=>{
		if(uParameterObj.status_tembak === undefined) uParameterObj.status_tembak = "REGULER";
		if(uParameterObj.nilai === undefined) uParameterObj.nilai = 0;
		if(uParameterObj.tgl_proses === undefined) uParameterObj.tgl_proses = UFunc.DbDate();
	},[uParameterObj,
	uParameterObj.status_tembak,
	uParameterObj.nilai,
	uParameterObj.tgl_proses
	]);
	React.useEffect(()=>{
		if(uParameterObj.status_tembak !== "REGULER"
			|| uParameterObj.tgl_proses !== UFunc.DbDate()
			|| (parseInt(uParameterObj.customer_id)||0) !== uCustomerID
			|| (parseInt(uParameterObj.payment_id)||0) > 0
			|| (parseFloat(uParameterObj.nilai)||0) > 0
			|| (uParameterObj.payment_ket||"") !== ""
			|| (uParameterObj.keterangan||"") !== ""
			) setFormupdated(true);
		else setFormupdated(false);
	},[uParameterObj,
	uParameterObj.status_tembak,
	uParameterObj.tgl_proses,
	uParameterObj.nilai,
	uParameterObj.customer_id,
	uParameterObj.payment_id,
	uParameterObj.payment_ket,
	uParameterObj.keterangan,
	]);
	React.useEffect(()=>{
		if(!uIsLookupShow) { 
			setInpLookupKwd("");setLookupjenis("");setLookupDataArr([]);
			return
		}
	},[uIsLookupShow]);
	React.useEffect(()=>{
		if(!uISDlglookupapishow)
			{ setDlglookupapidataObj({}); return }
	},[uISDlglookupapishow]);
	//---END EFFECT--/

	//console.log("(Titipandpmain) uNavigate => "+JSON.stringify(uNavigate))
	if(UFunc.isEmpty(uTokenObj)) { setLoading(true); prosesExpired(); return ""; }

	return (
		<>
		<div className="wrapper d-flex flex-column min-vh-100 bg-transparent">
		<CHeader position="sticky" className="p-0 mb-3">
		<CContainer fluid className="classheadermain" style={{height:64}}>
			<CHeaderToggler className="px-0" onClick={hdlKlikBack}>
			<CIcon icon="cilArrowCircleLeft" className="align-middle me-2" height={25}/>
			</CHeaderToggler>

			<CHeaderBrand className="me-auto">
				<small className="fw-bolder">{(uBahasaObj["menus_"+(uActiveroute||"").toLowerCase()]||"").toUpperCase()}</small>
			</CHeaderBrand>

			<CHeaderNav>
				<CLink onClick={hdlKlikReset} className="classcursorpointer classikon classikonreset me-2 align-middle"/>
				<MyProfileDetil {...props}/>
			</CHeaderNav>
		</CContainer>
		</CHeader>

		<div className="body flex-grow-1 px-2 px-lg-5 mb-3">
			<CContainer xxl className="p-0">
				{(uHTMLError500) ? (
					<MyPage500 content={uHTMLError500}
						isWithrefresh={true}
						hdlReset={()=>apiInitawal()}
						{...props}/>
				) : (
				<CCard className="classcardbg">
					<CCardHeader>
					<CIcon className="classikon classikontambah me-2" height={30}/>
					{uBahasaObj.caption_inputbaru||"caption_inputbaru"}
					</CCardHeader>

					<CCardBody>{contentMain()}</CCardBody>

					<CCardFooter className="d-flex justify-content-center">
					<MyButton
						iconname="cilCheckAlt"
						disabled={uIsFormprocess}
						onClick={()=>hdlKlikSimpan()}
						id="btnSimpan">{uBahasaObj.word_simpan||"word_simpan"} [F9]</MyButton>
					</CCardFooter>
				</CCard>
				)}
			</CContainer>
		</div>
		</div>

		<MyDialoglookup
			show={uIsLookupShow}
			toggle={()=>hdlToggleLookup()}
			dialogHeader={uDialogHeader||"dialogHeader"}
			inputValue={uInpLookupKwd}
			dataItemArr={uLookupDataArr} 
			onKlikPilihItem={hdlKlikPilihLookup}
			id="iddialoglookup"/>

		<MyDialoglookupapi
			show={uISDlglookupapishow}
			toggle={()=>setDlglookupapishow(false)}
			dialogHeader={uDlglookupapidataObj.header}
			inputValue={uDlglookupapidataObj.keyword}
			onKlikPilihItem={hdlKlikPilihLookupapi}
			targetApi={pjson.homepage+"api/api_customer/ld_lookup"} 
			kdownTrigger={uISDlglookupapitrigger}
			id="iddialoglookupapi"
			{...props}/>
		</>
	)//->
}	

export default Titipandpmain