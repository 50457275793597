import React from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { useSelector, useDispatch } from 'react-redux'
import {
	CHeader,
	CHeaderNav,
	CHeaderToggler,
	CHeaderDivider,
	CHeaderBrand,
	CFooter,
	CRow,
	CCol,
	CContainer,
	CForm,CFormInput,CFormTextarea,CInputGroup,CInputGroupText,CButton,
	CCard,
	CCardHeader,
	CCardBody,
	CCardFooter,
	CImage,
	CLink,
	CTooltip,
	CSpinner,
} from '@coreui/react';
import CIcon from '@coreui/icons-react'
import { isMobile } from "react-device-detect";
import * as FileSaver from "file-saver";
import XLSX from "sheetjs-style";
import {
	MyButton,
	MyDialoglookup,
	MyProfileDetil,
	MyPage500,
} from '../../components/index'
import { Konfirm } from '../../helpers/onConfirm'
import NumberFormat from 'react-number-format';
import { UFunc, cBulanPanjang, cBulanPendek } from '../../helpers/functions'

const pjson 	= require('../../../package.json')
const Payrollform = (props) => {
	const {
		setLoading,showToast,prosesExpired,setSessionaktif
	} = props;
	const { id } 				= useParams(); 

	//--DOM--/
	const uNavigate 			= useNavigate();
	const uDispatch 			= useDispatch();
	//--END DOM--/

	const uTabelID					= parseInt(id)||0;
	const uBahasaObj  				= useSelector(state => state.listBahasa);
	const uActiveroute  			= useSelector(state => state.activeRoute);
	const uSettinguserObj			= useSelector(state => state.gListUserSetting);
	const uIsScrollBottom			= useSelector(state => state.gIsScrollBottom);
	const uEfekapp					= useSelector(state => state.effectApp);
	const uHTMLstyleTabel			= useSelector(state => state.gHTMLstyleLaporan);

	const [uEfekview,setEfekview] 	= React.useState(false);
	const [uEfekview2,setEfekview2]	= React.useState(false);
	const uMaxData					= isMobile ? (uSettinguserObj.jml_mobile || 10) : (uSettinguserObj.jml_laporan || 100);
	const uKeywordobj				= JSON.parse(localStorage.getItem("listkeyword")) || {};
	const uTokenObj					= JSON.parse(localStorage.getItem("token")||"{}");
	const uIsHapusallow				= (uTokenObj.userhak==="FREEROLE"||uTokenObj.userhak==="ADMIN") ? true : false;
	const [uHTMLError500,setHTMLError500]= React.useState();
	const [uTimeelapse,setTimeelapse]	= React.useState(0); 
	const [uJmlData,setJmlData] 			= React.useState(0);
	const [uJmlHal,setJmlHal] 				= React.useState(1);
	const [uTabelRowSelect,setTabelRowSelect]= React.useState(-1);
	const [uTabelIDPilih,setTabelIDPilih]	= React.useState(0);
	const [uTabeleditObj,setTabeleditObj]	= React.useState({});

	const [uDatamainObj,setDatamainObj]	= React.useState({})
	const [uDataeditObj,setDataeditObj]	= React.useState({})
	const [uDatasumObj,setDatasumObj]	= React.useState({})
	const [uDatabtkArr,setDatabtkArr]	= React.useState([])
	const [uDatapotonganArr,setDatapotonganArr]	= React.useState([])
	const [uDataidentitasObj,setDataidentitasObj]	= React.useState({});

	//--DLG_FORM--/
	const fileType 			= "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8";
	const fileExtension 	= ".xlsx";
	const uFrameExport 		= document.getElementById("ifmcontentstoprint");
	//--END DLG_FORM--/

	//--DLGLOOKUP--/
	const uDataglkasArr 					= JSON.parse(localStorage.getItem("kasgllist")||"[]");
	const [uISDlglookupshow,setDlglookupshow]= React.useState(false);
	const [uDlglookupObj,setDlglookupObj]	= React.useState({});
	//--END DLGLOOKUP--/

	//---HANDEL--/
	const hdlKDownDocument = (_EV) => {}
	const hdlKlikReset = async() => {
		if(uHTMLError500) { apiLoaddata();return; }
		if(await(Konfirm("Apakah Anda Yakin ?"))) apiLoaddata();
	}
	const hdlDlglookuptoggle = () => { setDlglookupshow(false) }
	const hdlKlikDlglookuppilih = (_IDITEM) => {
		_IDITEM = parseInt(_IDITEM||0); if(_IDITEM <= 0) return;

		const vArridx 	= UFunc.getObjectIndeks(uDataglkasArr,_IDITEM,"value");

		let vElstmp 	= document.getElementById("btnDialogSimpan")
		uDataeditObj.gl_kas_id 		= uDataglkasArr[vArridx].value||"0";
		uDataeditObj.gl_kas_caption	= uDataglkasArr[vArridx].caption||"";
		setEfekview(!uEfekview);

		hdlDlglookuptoggle();
		let vTimeout = setTimeout(()=>{
			clearTimeout(vTimeout);
			const vElnextfocus = vElstmp;
			vElnextfocus && vElnextfocus.focus();
		},350)
	}
	const hdlToggletanggal = () => {
		uDatamainObj.init_editing = (uDatamainObj.init_editing||"TIDAK")==="TIDAK" ? "YA" : undefined;
		setEfekview(!uEfekview);
	}
	const hdlKlikSimpantanggal = () => {
		//--VALIDASI--/
		let vElfocus	= document.getElementById("inptglpenggajian")
		if((uDataeditObj.tgl_penggajian||"") === "") {
			vElfocus && vElfocus.focus();
			showToast("Rencana Tgl Penggajian Tidak Valid (1) !"); return;
		}
		const vTanggalDT = UFunc.toDate(uDataeditObj.tgl_penggajian);
		if(UFunc.DateIsMundur(vTanggalDT)) {
			vElfocus && vElfocus.focus();
			showToast("Rencana Tgl Penggajian Tidak Valid (2) !"); return;
		}
		vElfocus	= document.getElementById("inpkascaption")
		if(parseInt(uDataeditObj.gl_kas_id || 0) <= 0) {
			vElfocus && vElfocus.focus();
			showToast("Akun GL KAS Asal Harus DIPILIH !"); return;
		}
		//--END VALIDASI--/

		apiProsestanggal();
	}
	const hdlKlikKaslookup = () => {
		const vTmpArr	= uDataglkasArr.map((vItems)=>{
			return {id:vItems.value,caption:vItems.caption}
		})
		//console.log("(Payroll - hdlKlikKaslookup) vTmpArr => "+JSON.stringify(vTmpArr));
		uDlglookupObj.data_arr 	= vTmpArr || [];
		uDlglookupObj.header	= uBahasaObj.word_pilih+": "+
			(uBahasaObj.caption_akunkas||"").toUpperCase();
		setDlglookupshow(true)
	}
	const hdlKlikTabeledit = (_IDTABEL,_IDCAPTION,_JENIS,_SUBJENIS) => {
		_IDTABEL 	= parseInt(_IDTABEL)||0;
		_IDCAPTION 	= parseInt(_IDCAPTION)||0;
		_JENIS 		= (_JENIS||"").toUpperCase();
		_SUBJENIS 	= (_SUBJENIS||"").toUpperCase();

		const vRowIdx	= UFunc.getTabelRowIndeks("idtabeldata","idtr"+_IDTABEL);
		setTabelRowSelect(vRowIdx);

		const vItemArr	= uDatamainObj.items_arr || [];
		const vArridx 	= UFunc.getObjectIndeks(vItemArr,_IDTABEL);
		let vNilai 		= 0;
		let vJml 		= 0;
		if(_JENIS === "PENDAPATAN") {
			vJml	= parseFloat(vItemArr[vArridx]["btk_jml_"+_IDCAPTION]);
			vNilai	= parseFloat(vItemArr[vArridx]["btk_nsatuan_"+_IDCAPTION]);
		} else{
			vJml	= parseFloat(vItemArr[vArridx]["potongan_jml_"+_IDCAPTION]);
			vNilai	= parseFloat(vItemArr[vArridx]["potongan_nsatuan_"+_IDCAPTION]);
		}

		uTabeleditObj.id 		= _IDTABEL;
		uTabeleditObj.id_caption= _IDCAPTION;
		uTabeleditObj.jenis 	= _JENIS;
		uTabeleditObj.subjenis 	= _SUBJENIS;
		uTabeleditObj.jml 		= vJml;
		uTabeleditObj.nsatuan 	= vNilai;
		uTabeleditObj.is_jumlah = _SUBJENIS==="JML" ? true : undefined;
		setEfekview(!uEfekview);

		let vTimeout = setTimeout(()=>{
			clearTimeout(vTimeout);
			const vElfocus = document.getElementById("inptabelinput");
			vElfocus && vElfocus.focus();
		},250); return;
	}
	const hdlKDownTabelinput = (_EV) => {
		if(_EV.which === 27) {
			_EV.preventDefault();
			_EV.stopPropagation();
			setTabeleditObj({});setEfekview(!uEfekview);
			return;
		}
		if(_EV.which === 13) {
			_EV.preventDefault();
			_EV.stopPropagation();

			hdlKlikTabelinput();
			return;
		}
	}
	const hdlKlikTabelinput = () => {
		//--VALIDASI--/
		const vElfocus = document.getElementById("inptabelinput");
		if(uTabeleditObj.is_jumlah === true) {
			if(parseFloat(uTabeleditObj.jml || 0) < 0
				&& parseInt(uTabeleditObj.id_caption) === parseInt(uDatamainObj.gl_btk_umakan_id)
				&& uTabeleditObj.jenis === "PENDAPATAN"
				) {
				vElfocus && vElfocus.focus();
				showToast("Jml Yang Diinputkan Tidak Valid !"); return;
			}
		} else {
			if(parseFloat(uTabeleditObj.nsatuan || 0) < 0) {
				vElfocus && vElfocus.focus();
				showToast("Nilai Yang Diinputkan Tidak Valid !"); return;
			}
		}

		//--POTONGAN_LAIN_TOTAL_MINUS--/
		if(uTabeleditObj.jenis==="POTONGAN LAIN") {
			const vItemArr	= uDatamainObj.items_arr || [];
			const vArridx 	= UFunc.getObjectIndeks(vItemArr,uTabeleditObj.id);
			const vPinjamanlalu = parseFloat(vItemArr[vArridx].pinjaman_lalu || 0);

			if(vPinjamanlalu - parseFloat(uTabeleditObj.nsatuan) < 0) {
				vElfocus && vElfocus.focus();
				showToast("Nilai Yang Diinputkan Tidak Valid !"); return;
			}
		}
		//--END POTONGAN_LAIN_TOTAL_MINUS--/
		//--END VALIDASI--/

		apiProsestabel();
	}
	const hdlKlikCetak = () => {
		if(JSON.stringify(uDatamainObj || {}) === "{}") return;

		let vContent 	= contentHTMLcetak();
		let pri 		= uFrameExport.contentWindow;
		pri.document.open();
		pri.document.write(vContent);
		pri.document.close();
		pri.focus();
		pri.print();
	}
	const hdlKlikExcel = () => {
		if(JSON.stringify(uDatamainObj || {}) === "{}") return;

		initProcessExel();
	}

	const initProcessExel = () => {
		const vTanggalnowDT		= new Date();
		const vTglPenggajianDT	= UFunc.toDate(uDatamainObj.tgl_penggajian);

		const vBulanID		= vTglPenggajianDT.getMonth() + 1;
		const vTahun		= vTglPenggajianDT.getFullYear();
		const vItemArr		= uDatamainObj.items_arr || [];
		const vPotbpjsArr	= uDatapotonganArr.filter(vItems=>vItems.jenis==="POTONGAN BPJS"); 
		const vPotlainArr	= uDatapotonganArr.filter(vItems=>vItems.jenis==="POTONGAN LAIN");
		const gl_btk_umakan_id= parseInt(uDatamainObj.gl_btk_umakan_id) || 0;

		//console.log("(Payrollform - initProcessExel) uDatabtkArr => "+JSON.stringify(uDatabtkArr))

		let vColspan 		= 8;
		let vDatasetsArr 	= [];
		let vRowcount		= 0;
		let vColposwidth	= 10;

		let vTmpObj	= {
			no : "",
			nama_karyawan: "",
		}

		uDatabtkArr.map((vItembtk,vKeybtk)=>{
			if(parseInt(vItembtk.value) === gl_btk_umakan_id) {
				vTmpObj["btk_jml_"+gl_btk_umakan_id] 	= "";
				vTmpObj["btk_nsatuan_"+gl_btk_umakan_id] = "";
				vColspan++;
				vColspan++;
			}
			vTmpObj["btk_nilai_"+(vItembtk.value||"0")] = "";
			vColspan++;
		})

		vTmpObj["jumlah"] 					= "";
		vPotbpjsArr.map((vItems,vKeys)=>{
			vColspan++;
			vTmpObj["bpjs_"+vItems.value] = "";
		});
		vTmpObj["bpjs_jumlah"] 		= "";

		vTmpObj["diterima_1"] 		= "";
		vTmpObj["pinjaman_lalu"] 	= "";
		vPotlainArr.map((vItems,vKeys)=>{
			vColspan++;
			vTmpObj["pinjaman_"+vItems.value] = "";
		});
		vTmpObj["pinjaman_jumlah"] 	= "";
		vTmpObj["diterima_2"] 		= "";

		vDatasetsArr.push(vTmpObj);
		vRowcount++;

		//console.log("uDataviewArr => "+JSON.stringify(uDataviewArr));
		vItemArr.map((vItems,vKeys)=>{
			const {
				pinjaman_lalu,is_tabelprocessing,
				nama_karyawan,id
			} = vItems;

			const vBTKJumlah 	= (uDatabtkArr.reduce((a,b)=>{ 
				return a + ( parseFloat(vItems["btk_jml_"+b.value]) * parseFloat(vItems["btk_nsatuan_"+b.value]) )
			},0) );	
			const vBPJSjumlah 	= (vPotbpjsArr.reduce((a,b)=>{ 
				return a + ( parseFloat(vItems["potongan_jml_"+b.value]) * parseFloat(vItems["potongan_nsatuan_"+b.value]) )
			},0) );
			const vPotlainjumlah= (vPotlainArr.reduce((a,b)=>{ 
				return a + ( parseFloat(vItems["potongan_jml_"+b.value]) * parseFloat(vItems["potongan_nsatuan_"+b.value]) )
			},0) );	

			const vDiterima_1 	= vBTKJumlah - vBPJSjumlah; 
			const vPinjamannow 	= parseFloat(pinjaman_lalu||0) - vPotlainjumlah;
			const vDiterima_2 	= vDiterima_1 - vPotlainjumlah;

			vColposwidth = (nama_karyawan||"").length > vColposwidth 
				? (nama_karyawan||"").length : vColposwidth; 

			vTmpObj	= {
				no : {v:vKeys+1,t:"n"},
				nama_karyawan: (nama_karyawan||"Undf"),
			}
			uDatabtkArr.map((vItembtk,vKeybtk)=>{
				if(parseInt(vItembtk.value) === gl_btk_umakan_id) {
					const vNilaijumlah	= (parseInt(vItems["btk_jml_"+gl_btk_umakan_id])||0) *
						(parseFloat(vItems["btk_nsatuan_"+gl_btk_umakan_id])||0);

					vTmpObj["btk_jml_"+gl_btk_umakan_id] 	= { v:parseInt(vItems["btk_jml_"+gl_btk_umakan_id]||"0"), t:"n", s:{numFmt: "#,##0"} }
					vTmpObj["btk_nsatuan_"+gl_btk_umakan_id] = { v:parseFloat(vItems["btk_nsatuan_"+gl_btk_umakan_id]||"0"), t:"n", s:{numFmt: "#,##0"} }
					vTmpObj["btk_nilai_"+gl_btk_umakan_id] = { v:vNilaijumlah, t:"n", s:{numFmt: "#,##0"} }
				} else {
					vTmpObj["btk_nilai_"+(vItembtk.value||"0")] = { v:parseFloat(vItems["btk_nsatuan_"+(vItembtk.value||"0")]||"0"), t:"n", s:{numFmt: "#,##0"} }
				}
			})

			vTmpObj["jumlah"] 					= { v:vBTKJumlah, t:"n", s:{numFmt: "#,##0"} }

			vPotbpjsArr.map((vItempot,vKeypot)=>{
				const vNilai	= parseFloat(vItems["potongan_nsatuan_"+vItempot.value])
				vTmpObj["bpjs_"+vItempot.value] = { v:vNilai, t:"n", s:{numFmt: "#,##0"} }
			});

			vTmpObj["bpjs_jumlah"] 		= { v:vBPJSjumlah, t:"n", s:{numFmt: "#,##0"} }
			vTmpObj["diterima_1"] 		= { v:vDiterima_1, t:"n", s:{numFmt: "#,##0"} }
			vTmpObj["pinjaman_lalu"] 	= { v:parseFloat(pinjaman_lalu||"0"), t:"n", s:{numFmt: "#,##0"} }
			vPotlainArr.map((vItempot,vKeypot)=>{
				const vNilai	= parseFloat(vItems["potongan_nsatuan_"+vItempot.value])
				vTmpObj["pinjaman_"+vItempot.value] = { v:vNilai, t:"n", s:{numFmt: "#,##0"} }
			});
			vTmpObj["pinjaman_jumlah"] 	= { v:vPinjamannow, t:"n", s:{numFmt: "#,##0"} }
			vTmpObj["diterima_2"] 		= { v:vDiterima_2, t:"n", s:{numFmt: "#,##0"} }

			vDatasetsArr.push(vTmpObj);
			vRowcount++;
		});

		//--SUM_NILAI--/
		vTmpObj	= {
			no : {v: "JUMLAH",t:"s",s:{alignment:{horizontal:"center"},font:{bold:true} }},
			nama_karyawan: "",
		};
		uDatabtkArr.map((vItembtk,vKeybtk)=>{
			if(parseInt(vItembtk.value) === gl_btk_umakan_id) {
				vTmpObj["btk_jml_"+gl_btk_umakan_id] 	= "";
				vTmpObj["btk_nsatuan_"+gl_btk_umakan_id] = "";
			}
			vTmpObj["btk_nilai_"+(vItembtk.value||"0")] = { v:parseFloat(uDatasumObj["sum_btk_"+vItembtk.value])||0, t:"n", s:{numFmt: "#,##0"} }
		});
		vTmpObj["jumlah"] 			= { v:parseFloat(uDatasumObj.sum_btk_jumlah)||0, t:"n", s:{numFmt: "#,##0"} }

		vPotbpjsArr.map((vItempot,vKeypot)=>{
			const vNilai	= parseFloat(uDatasumObj["sum_bpjs_"+vItempot.value])||0;
			vTmpObj["bpjs_"+vItempot.value] = { v:vNilai, t:"n", s:{numFmt: "#,##0"} }
		});
		vTmpObj["bpjs_jumlah"] 		= { v:parseFloat(uDatasumObj.sum_bpjs_jumlah)||0, t:"n", s:{numFmt: "#,##0"} }

		vTmpObj["diterima_1"] 		= { v:parseFloat(uDatasumObj.sum_diterima_1)||0, t:"n", s:{numFmt: "#,##0"} }
		vTmpObj["pinjaman_lalu"] 	= { v:parseFloat(uDatasumObj.sum_pinjaman_lalu)||0, t:"n", s:{numFmt: "#,##0"} }
		vPotlainArr.map((vItempot,vKeypot)=>{
			const vNilai	= parseFloat(uDatasumObj["sum_lain_"+vItempot.value])
			vTmpObj["pinjaman_"+vItempot.value] = { v:vNilai, t:"n", s:{numFmt: "#,##0"} }
		});
		vTmpObj["pinjaman_jumlah"] 	= { v:parseFloat(uDatasumObj.sum_pinjamannow)||0, t:"n", s:{numFmt: "#,##0"} }
		vTmpObj["diterima_2"] 		= { v:parseFloat(uDatasumObj.sum_diterima_2)||0, t:"n", s:{numFmt: "#,##0"} }

		vDatasetsArr.push(vTmpObj);
		vRowcount++;
		//--END SUM_NILAI--/

		const wsrows	= [{hpt:21}]
		const wscols	= [
			{wpx:40},
			{wch:vColposwidth},
		];
		uDatabtkArr.map((vItems,vKeys)=>{
			if(parseInt(vItems.value) === gl_btk_umakan_id) {
				wscols.push({wpx:60});
				wscols.push({wpx:80});
			}
			wscols.push({wpx:110});
		});
		wscols.push({wpx:110});//--JUMLAH--/

		vPotbpjsArr.map((vItempot,vKeypot)=>{
			wscols.push({wpx:110});
		})
		wscols.push({wpx:110});//--BPJS_JUMLAH--/

		wscols.push({wpx:110});//--DITERIMA_1--/
		wscols.push({wpx:110});//--PINJAMAN_LALU--/
		
		vPotlainArr.map((vItempot,vKeypot)=>{
			wscols.push({wpx:110});
		})
		wscols.push({wpx:110});//--PINJAMAN_JUMLAH--/
		wscols.push({wpx:110});//--DITERIMA_2--/

		const wsmerge	= [
			{s:{r:0,c:0},e:{r:0,c:vColspan - 1}},
			{s:{r:vRowcount,c:0},e:{r:vRowcount,c:1}}
		]

		const vNamafile = 
			vTanggalnowDT.getFullYear()+
			UFunc.leadZero(vTanggalnowDT.getMonth()+1)+
			UFunc.leadZero(vTanggalnowDT.getDate())+"_rekappayroll_"+
			(cBulanPendek[vBulanID-1]).toUpperCase()+"_"+vTahun+
		"";
		const vSheetlabel = (cBulanPendek[vBulanID-1])+vTahun;

		const ws 		= XLSX.utils.json_to_sheet(vDatasetsArr);
		//--CUSTOM_HEADER--/
		const vJudullaporan = uBahasaObj["menus_"+(uActiveroute||"").toLowerCase()]+
			"\r\Tgl Penggajian "+UFunc.TglAngka(uDatamainObj.tgl_penggajian)+
		"";
		const vHeaderArr = [
			{v:"No",s:{alignment:{horizontal:"center"},font:{bold:true}}}, 
			{v:"Nama Karyawan",s:{alignment:{horizontal:"left"},font:{bold:true}}}, 
		];
		uDatabtkArr.map((vItems,vKeys)=>{
			if(parseInt(vItems.value) === gl_btk_umakan_id) {
				vHeaderArr.push({v:"H.Kerja",s:{alignment:{horizontal:"center"},font:{bold:true}}}) 
				vHeaderArr.push({v:"N.Satuan",s:{alignment:{horizontal:"center"},font:{bold:true}}}) 
			}
			vHeaderArr.push({v:vItems.nama_gl||"Undf",s:{alignment:{horizontal:"center"},font:{bold:true}}}) 
		});
		vHeaderArr.push({v:"JUMLAH",s:{alignment:{horizontal:"center"},font:{bold:true}}})//--JUMLAH--/
		
		vPotbpjsArr.map((vItempot,vKeypot)=>{
			vHeaderArr.push({v:vItempot.caption_slip||"Undf",s:{alignment:{horizontal:"center"},font:{bold:true}}})
		})
		vHeaderArr.push({v:"JUMLAH",s:{alignment:{horizontal:"center"},font:{bold:true}}})//--BPJS_JUMLAH--/

		vHeaderArr.push({v:"Diterima (1)",s:{alignment:{horizontal:"center"},font:{bold:true}}})//--DITERIMA_1--/
		vHeaderArr.push({v:"Pinjaman Bln.Lalu",s:{alignment:{horizontal:"center"},font:{bold:true}}})//--PINJAMAN_LALU--/
		
		vPotlainArr.map((vItempot,vKeypot)=>{
			vHeaderArr.push({v:vItempot.caption_slip||"Undf",s:{alignment:{horizontal:"center"},font:{bold:true}}})
		})
		vHeaderArr.push({v:"Pinjaman s.d.Bln Ini",s:{alignment:{horizontal:"center"},font:{bold:true}}})//--PINJAMAN_JUMLAH--/
		vHeaderArr.push({v:"Diterima (2)",s:{alignment:{horizontal:"center"},font:{bold:true}}})//--DITERIMA_2--/

		XLSX.utils.sheet_add_aoa(ws, [[{v:vJudullaporan,t:"s",s:{
			alignment:{horizontal:"center",wrapText: true},
			font:{bold:true} 
		}}]], { origin: "A1" });
		XLSX.utils.sheet_add_aoa(ws, [vHeaderArr], { origin: "A2" });
		//--END CUSTOM_HEADER--*/
		ws['!rows'] 	= wsrows;
		ws['!cols'] 	= wscols;
		ws["!merges"] 	= wsmerge;

		const wb 		= { Sheets: { [vSheetlabel]: ws }, SheetNames: [vSheetlabel] };
		const excelBuffer = XLSX.write(wb, { bookType: "xlsx", bookSST: true, type: "array" });
		const data 		= new Blob([excelBuffer], { type: fileType });
		FileSaver.saveAs(data, vNamafile + fileExtension);
	}
	//---END HANDEL--/

	//---INIT_PROCESS--/
	const prosesSUM = () => {
		const vTmpObj 		= {}
		const vItemArr		= uDatamainObj.items_arr || [];
		const vPotbpjsArr	= uDatapotonganArr.filter(vItems=>vItems.jenis==="POTONGAN BPJS"); 
		const vPotlainArr	= uDatapotonganArr.filter(vItems=>vItems.jenis==="POTONGAN LAIN");

		vItemArr.map((vItems,vKeys)=>{
			vTmpObj.sum_pinjaman_lalu = (vTmpObj.sum_pinjaman_lalu||0) + parseFloat(vItems.pinjaman_lalu||0);

			let vBTKJumlah = 0;
			uDatabtkArr.map((vItembtk,vKeybtk)=>{
				vTmpObj["sum_btk_"+vItembtk.value] = 
					(vTmpObj["sum_btk_"+vItembtk.value]||0) + (
						parseFloat(vItems["btk_jml_"+vItembtk.value] || 0) *
						parseFloat(vItems["btk_nsatuan_"+vItembtk.value] || 0)
						);

				vBTKJumlah = vBTKJumlah + (vTmpObj["sum_btk_"+vItembtk.value]||0);
			});
			vTmpObj.sum_btk_jumlah = vBTKJumlah; 

			let vBPJSjumlah = 0;
			vPotbpjsArr.map((vItempot,vKeypot)=>{
				vTmpObj["sum_bpjs_"+vItempot.value] = 
					(vTmpObj["sum_bpjs_"+vItempot.value]||0) + (
						parseFloat(vItems["potongan_jml_"+vItempot.value] || 0) *
						parseFloat(vItems["potongan_nsatuan_"+vItempot.value] || 0)
						);

				vBPJSjumlah = vBPJSjumlah + (vTmpObj["sum_bpjs_"+vItempot.value]||0);
			});
			vTmpObj.sum_bpjs_jumlah = vBPJSjumlah; 

			let vLainjumlah = 0;
			vPotlainArr.map((vItempot,vKeypot)=>{
				vTmpObj["sum_lain_"+vItempot.value] = 
					(vTmpObj["sum_lain_"+vItempot.value]||0) + (
						parseFloat(vItems["potongan_jml_"+vItempot.value] || 0) *
						parseFloat(vItems["potongan_nsatuan_"+vItempot.value] || 0)
						);
				vLainjumlah = vLainjumlah + (vTmpObj["sum_lain_"+vItempot.value]||0);
			});
			vTmpObj.sum_lain_jumlah = vLainjumlah; 
		});
		vTmpObj.sum_diterima_1 	= vTmpObj.sum_btk_jumlah - vTmpObj.sum_bpjs_jumlah;
		vTmpObj.sum_pinjamannow = vTmpObj.sum_pinjaman_lalu - vTmpObj.sum_lain_jumlah;
		vTmpObj.sum_diterima_2 	= vTmpObj.sum_diterima_1 - vTmpObj.sum_lain_jumlah;

		setDatasumObj(vTmpObj);
		setEfekview(!uEfekview);
	}
	//---END INIT_PROCESS--/

	//---CONTENT--/
	const contentMobileSM = () => {
		if(uHTMLError500) return "";

		return (
		<CCard className="classcardbg">
		<CCardHeader className="text-muted"><small>View Not Affordable</small></CCardHeader>
		<CCardBody className="fst-italic">
			<p className="fw-bolder">Maaf,</p>
			<p>Fitur Ini Tidak Dapat Ditampilkan Dalam Mode Seluler.
			Mohon Gunakan Perangkat dengan Layar yang lebih Besar..</p>
			<p><small className="text-muted">Terimakasih,</small></p>
		</CCardBody>
		</CCard>
		)//>
	}
	const contentTanggal = () => {
		if(uHTMLError500) return "";

		return (
		<small>
		<CCard className="classcardbg h-100">
		<CCardHeader className="py-1 d-flex justify-content-between">
			<span className="text-muted">Tanggal Penggajian</span>
			{(uDatamainObj.status_jurnal||"SUDAH")==="BELUM" && (
			<>
			{(uDatamainObj.init_editing||"TIDAK")!=="YA" ? (
			<CLink className="classcursorpointer classikontabel classikonedit"
				onClick={()=>hdlToggletanggal()}/>
			) : (
			<>
				{(uDatamainObj.is_tanggalprocessing||false)===true ? (
				<CSpinner color="success" size="sm" height={30}/>
				) : (
				<CButton size="sm" color="success"
					className="text-white fw-bolder"
					onClick={()=>hdlKlikSimpantanggal()}
					id="btnSimpantanggal">
					<CIcon icon="cilCheckCircle" className="me-2 align-middle"/>Simpan
				</CButton>
				)}
			</>
			)}
			</>
			)}
		</CCardHeader>

		<CCardBody className="px-0 px-lg-2">
		<CRow className="mx-0 my-1">
		<CCol className="">
			<span className="d-none d-lg-block">Tgl Penggajian (Rencana)</span>
			<small className="d-lg-none">Tgl Penggajian</small>
		</CCol>
		<CCol md="7" lg="6" className="text-end fw-bolder">
			{(uDatamainObj.init_editing||"TIDAK")!=="YA" 
			? (<>{UFunc.TglAngka(uDatamainObj.tgl_penggajian)}</>)
			: (
			<CFormInput size="sm" type="date"
				disabled={(uDatamainObj.is_tanggalprocessing||false)}
				value={uDataeditObj.tgl_penggajian||""}
				onChange={(e)=>{uDataeditObj.tgl_penggajian=e.target.value;setEfekview(!uEfekview)}}
				id="inptglpenggajian"/>
			)}
		</CCol>
		</CRow>

		<CRow className="mx-0 my-1">
		<CCol className="">
			<span className="d-none d-lg-block">Akun GL Kas ASAL</span>
			<small className="d-lg-none">GL Kas ASAL</small>
		</CCol>
		<CCol md="7" lg="6" className="text-end fw-bolder text-uppercase">
			{(uDatamainObj.init_editing||"TIDAK")!=="YA" 
			? (<>{(uDatamainObj.gl_kas_caption||"-")}</>)
			: (
			<CInputGroup size="sm">
			<CFormInput size="sm" readOnly={true}
				disabled={(uDatamainObj.is_tanggalprocessing||false)}
				className="bg-light"
				placeholder="--Pilih Akun Kas"
				value={uDataeditObj.gl_kas_caption||""}
				onClick={()=>hdlKlikKaslookup()}
				id="inpkascaption"/>
			<CButton size="sm" color="dark"
				disabled={(uDatamainObj.is_tanggalprocessing||false)}
				onClick={()=>hdlKlikKaslookup()}
				id="btnkasloopkup">
				<CIcon icon="cilMagnifyingGlass"/>
			</CButton>
			</CInputGroup>
			)}
		</CCol>
		</CRow>
		</CCardBody>
		</CCard>
		</small>
		)//>
	}
	const contentStatus = () => {
		if(uHTMLError500) return "";

		return (
		<CCard className="classcardbg h-100">
		<CCardHeader className="py-1 text-muted"><small>Status JURNAL</small></CCardHeader>
		<CCardBody className="text-center">
			<big className={"fw-bolder d-none d-lg-block"+((uDatamainObj.status_jurnal||"SUDAH")==="SUDAH"?" text-danger":" text-success")} 
				style={{fontSize:"200%"}}>
			&middot;&middot;
			{uDatamainObj.status_jurnal && (
			<>
			{(uDatamainObj.status_jurnal||"SUDAH")==="SUDAH"?"SUDAH DIJURNAL":"BELUM DIJURNAL"}
			</>
			)}
			&middot;&middot;
			</big>

			<span className={"fw-bolder d-lg-none"+((uDatamainObj.status_jurnal||"SUDAH")==="SUDAH"?" text-danger":" text-success")} 
				style={{fontSize:"120%"}}>
			&middot;&middot;
			{uDatamainObj.status_jurnal && (
			<>
			{(uDatamainObj.status_jurnal||"SUDAH")==="SUDAH"?"SUDAH DIJURNAL":"BELUM DIJURNAL"}
			</>
			)}
			&middot;&middot;
			</span>
		</CCardBody>
		</CCard>
		)//>
	}
	const contentAksi = () => {
		if(uHTMLError500) return "";

		return (
		<CCard className="classcardbg h-100">
		<CCardHeader className="py-1 text-muted"><small>Tombol Action</small></CCardHeader>

		<CCardBody className="d-flex justify-content-center align-items-center">
			<CTooltip content="--Cetak SLIP GAJI">
			<CLink className="classcursorpointer classikon classikoncetak"
				onClick={()=>hdlKlikCetak()}/>
			</CTooltip>

			<span className="mx-1 d-none d-lg-block">&middot;</span>
			
			<CTooltip content="--Konvert Rekap Gaji to EXCEL">
			<CLink className="classcursorpointer classikon classikonexcel"
				onClick={()=>hdlKlikExcel()}/>
			</CTooltip>
		</CCardBody>
		</CCard>
		)//>
	}
	const contentItem = () => {
		if(uHTMLError500) return "";

		let vNumber			= 0;
		const vItemArr		= uDatamainObj.items_arr || [];
		const vPotbpjsArr	= uDatapotonganArr.filter(vItems=>vItems.jenis==="POTONGAN BPJS"); 
		const vPotlainArr	= uDatapotonganArr.filter(vItems=>vItems.jenis==="POTONGAN LAIN");
		const gl_btk_umakan_id= parseInt(uDatamainObj.gl_btk_umakan_id) || 0;

		const vFilterBtkUmakanObj = (uDatabtkArr.filter(vItems=>
			parseInt(vItems.value) === parseInt(uDatamainObj.gl_btk_umakan_id||0))||[])[0];

		const vJmlKolnilai	= 
			(uDatabtkArr.length+1+2) +
			(vPotbpjsArr.length + 1) +
			(vPotlainArr.length + 2) +
			2;

		const vWidthno		= 50;
		const vWidthFirstkol= isMobile ? 175 : 250;
		const vWidthkolnilai= 125;
		const vWidthtabel	= vWidthno+vWidthFirstkol+(vWidthkolnilai * vJmlKolnilai);
		
		const vStylekolfixed = {
			position: "sticky",
			left: 0,
			background: "white",
			zIndex: 1,
		}

		return (
		<CCard className="classcardbg" id="idcardstatus">
		<CCardHeader className="py-1 text-muted"><small>Item Payroll</small></CCardHeader>
		
		<CCardBody className="py-1 px-0">
		<div style={{overflowX:"auto"}}
			className=""
			id="iddivtabel">
		<div className="d-none">vItemArr = {JSON.stringify(vItemArr)}</div>
		<table 
			style={{width:vWidthtabel,borderCollapse:"separate"}}
			className="table mx-auto table-hover" 
			id="idtabeldata">
		<thead>
			<tr className="align-top text-center classfontsmaller">
			<th rowSpan={2} width={vWidthno} style={vStylekolfixed} className="border bg-light">No</th>
			<th rowSpan={2} width={vWidthFirstkol} style={vStylekolfixed} className="border bg-light">
				{uBahasaObj.caption_namakaryawan||"Nama Karyawan"}
			</th>
			{uDatabtkArr.map((vItembtk,vKeybtk)=>{
				if(parseInt(vItembtk.value) === parseInt(uDatamainObj.gl_btk_umakan_id||0))
					return (
					<th colSpan={3} key={vKeybtk} className="border">
					{vItembtk.nama_gl||"Undf"}
					</th>
					)

				return (
				<th rowSpan={2} key={vKeybtk} width={vWidthkolnilai} className="border">
				{vItembtk.nama_gl||"Undf"}
				</th>
				)
			})}
			<th rowSpan={2} width={vWidthkolnilai} className="border">Jumlah</th>
			<th colSpan={vPotbpjsArr.length+1} className="border">Potongan BPJS</th>
			<th rowSpan={2} width={vWidthkolnilai} className="border">Diterima (1)</th>
			<th rowSpan={2} width={vWidthkolnilai} className="border">Pinj.Bln Lalu</th>
			{vPotlainArr.map((vItems,vKeys)=>{
				return (
				<th rowSpan={2} width={vWidthkolnilai} className="border">{vItems.caption_slip}</th>
				)
			})}
			<th rowSpan={2} width={vWidthkolnilai} className="border">Pinj. s.d Bln Ini</th>
			<th rowSpan={2} width={vWidthkolnilai} className="border">Diterima (2)</th>
			</tr>

			<tr className="text-center align-top classfontsmaller">
			<th width={110} className="border">Hari Kerja</th>
			<th width={vWidthkolnilai} className="border">N.Satuan</th>
			<th width={vWidthkolnilai} className="border">Jumlah</th>

			{vPotbpjsArr.map((vItems,vKeys)=>{
				return (
				<th width={vWidthkolnilai} className="border">{UFunc.getWordnumber(vItems.caption_slip,2)}</th>
				)
			})}
			<th rowSpan={2} width={vWidthkolnilai} className="border">Jumlah</th>
			</tr>
		</thead>
		<tbody>
		{vItemArr.map((vItems,vKeys)=>{
			const {
				pinjaman_lalu,is_tabelprocessing,
				nama_karyawan,id
			} = vItems;
			vNumber++;

			const vBTKJumlah 	= (uDatabtkArr.reduce((a,b)=>{ 
				return a + ( parseFloat(vItems["btk_jml_"+b.value]) * parseFloat(vItems["btk_nsatuan_"+b.value]) )
			},0) );	
			const vBPJSjumlah 	= (vPotbpjsArr.reduce((a,b)=>{ 
				return a + ( parseFloat(vItems["potongan_jml_"+b.value]) * parseFloat(vItems["potongan_nsatuan_"+b.value]) )
			},0) );
			const vPotlainjumlah= (vPotlainArr.reduce((a,b)=>{ 
				return a + ( parseFloat(vItems["potongan_jml_"+b.value]) * parseFloat(vItems["potongan_nsatuan_"+b.value]) )
			},0) );	

			const vDiterima_1 	= vBTKJumlah - vBPJSjumlah; 
			const vPinjamannow 	= parseFloat(pinjaman_lalu||0) - vPotlainjumlah;
			const vDiterima_2 	= vDiterima_1 - vPotlainjumlah;

			return (
			<tr id={"idtr"+id} key={vKeys} className={"classfontsmaller "+((uTabelRowSelect===vKeys && !isMobile)?"classrowselect":"")}>
			<td align="right" style={vStylekolfixed} className="px-1 bg-light">{vNumber}.</td>
			<td className="px-2 bg-light text-info" style={vStylekolfixed}>
				{nama_karyawan}
			</td>

			{uDatabtkArr.map((vItembtk,vKeybtk)=>{
				const vNilai	= //parseFloat(vItems["btk_jml_"+vItembtk.value]) *
					parseFloat(vItems["btk_nsatuan_"+vItembtk.value]);

				if(parseInt(vItembtk.value) === parseInt(uDatamainObj.gl_btk_umakan_id||0)) {
					const vJml	= parseFloat(vItems["btk_jml_"+vItembtk.value]);

					return (
					<>
					<td key={vKeybtk} className="px-1 text-center">
					{uDatamainObj.status_jurnal==="BELUM" ? (
					<>
					{parseInt(uTabeleditObj.id||0)===parseInt(id) 
						&& uTabeleditObj.jenis==="PENDAPATAN"
						&& (uTabeleditObj.is_jumlah||false)===true
						&& parseInt(uTabeleditObj.id_caption)===parseInt(vItembtk.value)
						? (
						<>
							{(is_tabelprocessing||false)===true ? (
								<CSpinner size="sm" color="primary"/>
							) : (
								<CInputGroup size="sm">
									<CFormInput size="sm" type="number"
										className="text-end"
										value={uTabeleditObj.jml}
										onChange={(e)=>{uTabeleditObj.jml=e.target.value;setEfekview(!uEfekview)}}
										onKeyDown={(e)=>hdlKDownTabelinput(e)}
										onFocus={(e)=>e.target.select()}
									id={"inptabelinput"}/>
								<CButton size="sm" color="dark"
									onClick={()=>hdlKlikTabelinput()}
									id="btnnilaibtk">
									<CIcon icon="cilCheckAlt"/>
								</CButton>
								</CInputGroup>
							)}
						</>
						) : (
						<CLink 
							onClick={()=>hdlKlikTabeledit(id,vItembtk.value,"PENDAPATAN","JML")}
							className="classcursorpointer">
						{UFunc.formatAngka(vJml)}
						</CLink>
					)}	
					</>	
					) : (<>{UFunc.formatAngka(vJml)}</>)}
					</td>

					<td className="px-1 text-end">
					{uDatamainObj.status_jurnal==="BELUM" ? (
					<>
					{parseInt(uTabeleditObj.id||0)===parseInt(id) 
						&& uTabeleditObj.jenis==="PENDAPATAN"
						&& (uTabeleditObj.is_jumlah||false)===false
						&& parseInt(uTabeleditObj.id_caption)===parseInt(vItembtk.value)
						? (
						<>
							{(is_tabelprocessing||false)===true ? (
								<CSpinner size="sm" color="primary"/>
							) : (
							<CInputGroup size="sm">
							<NumberFormat 
								value={uTabeleditObj.nsatuan}
								displayType={'text'} 
								thousandSeparator={"."} 
								decimalSeparator={","}
								onValueChange={(values) => uTabeleditObj.nsatuan=(values.value)}
								renderText={values => (
									<CFormInput size="sm"
										className="text-end"
										value={values}
										onChange={(e)=>{uTabeleditObj.nsatuan=e.target.value;setEfekview(!uEfekview)}}
										onKeyDown={(e)=>hdlKDownTabelinput(e)}
										onFocus={(e)=>e.target.select()}
									id={"inptabelinput"}/>
								)} 
								prefix=""/>
							<CButton size="sm" color="dark"
								onClick={()=>hdlKlikTabelinput()}
								id="btnnilaibtk">
								<CIcon icon="cilCheckAlt"/>
							</CButton>
							</CInputGroup>
							)}
						</>
						) : (
						<CLink 
							onClick={()=>hdlKlikTabeledit(id,vItembtk.value,"PENDAPATAN")}
							className="classcursorpointer">
						{UFunc.formatAngka(vNilai)}
						</CLink>
					)}	
					</>	
					) : (<>{UFunc.formatAngka(vNilai)}</>)}
					</td>

					<td className="px-1 text-end">
						{UFunc.formatAngka(parseInt(vItems["btk_jml_"+vItembtk.value]) *
							vNilai
						)}
					</td>
					</>
					)
				}


				//console.log("(Payrollform - contentItem) vItems[btk_nsatuan_"+vItembtk.value+"] "+vItems["btk_nsatuan_"+vItembtk.value])
				return (
				<td key={vKeybtk} className="px-1 text-end">
				{uDatamainObj.status_jurnal==="BELUM" ? (
				<>
				{parseInt(uTabeleditObj.id||0)===parseInt(id) 
					&& uTabeleditObj.jenis==="PENDAPATAN"
					&& parseInt(uTabeleditObj.id_caption)===parseInt(vItembtk.value)
					? (
					<>
						{(is_tabelprocessing||false)===true ? (
							<CSpinner size="sm" color="primary"/>
						) : (
						<CInputGroup size="sm">
						<NumberFormat 
							value={uTabeleditObj.nsatuan}
							displayType={'text'} 
							thousandSeparator={"."} 
							decimalSeparator={","}
							onValueChange={(values) => uTabeleditObj.nsatuan=(values.value)}
							renderText={values => (
								<CFormInput size="sm"
									className="text-end"
									value={values}
									onChange={(e)=>{uTabeleditObj.nsatuan=e.target.value;setEfekview(!uEfekview)}}
									onKeyDown={(e)=>hdlKDownTabelinput(e)}
									onFocus={(e)=>e.target.select()}
								id={"inptabelinput"}/>
							)} 
							prefix=""/>
						<CButton size="sm" color="dark"
							onClick={()=>hdlKlikTabelinput()}
							id="btnnilaibtk">
							<CIcon icon="cilCheckAlt"/>
						</CButton>
						</CInputGroup>
						)}
					</>
					) : (
					<CLink 
						onClick={()=>hdlKlikTabeledit(id,vItembtk.value,"PENDAPATAN")}
						className="classcursorpointer">
					{UFunc.formatAngka(vNilai)}
					</CLink>
				)}	
				</>	
				) : (<>{UFunc.formatAngka(vNilai)}</>)}
				</td>
				)
			})}

			<td className="px-1 text-end">
				{UFunc.formatAngka(vBTKJumlah)}
			</td>

			{vPotbpjsArr.map((vItempot,vKeypot)=>{
				const vNilai	= //parseFloat(vItems["potongan_jml_"+vItempot.value]) *
					parseFloat(vItems["potongan_nsatuan_"+vItempot.value])
				
				return (
				<td key={vKeypot} className="px-1 text-end text-danger">
				{uDatamainObj.status_jurnal==="BELUM" ? (
				<>
				{parseInt(uTabeleditObj.id||0)===parseInt(id) 
					&& uTabeleditObj.jenis===vItempot.jenis
					&& parseInt(uTabeleditObj.id_caption)===parseInt(vItempot.value)
					? (
					<>
						{(is_tabelprocessing||false)===true ? (
							<CSpinner size="sm" color="primary"/>
						) : (
						<CInputGroup size="sm">
						<NumberFormat 
							value={uTabeleditObj.nsatuan}
							displayType={'text'} 
							thousandSeparator={"."} 
							decimalSeparator={","}
							onValueChange={(values) => uTabeleditObj.nsatuan=(values.value)}
							renderText={values => (
								<CFormInput size="sm"
									className="text-end"
									value={values}
									onChange={(e)=>{uTabeleditObj.nsatuan=e.target.value;setEfekview(!uEfekview)}}
									onKeyDown={(e)=>hdlKDownTabelinput(e)}
									onFocus={(e)=>e.target.select()}
								id={"inptabelinput"}/>
							)} 
							prefix=""/>
						<CButton size="sm" color="dark"
							onClick={()=>hdlKlikTabelinput()}
							id="btnnilaibpjs">
							<CIcon icon="cilCheckAlt"/>
						</CButton>
						</CInputGroup>
						)}
					</>
					) : (
					<CLink 
						onClick={()=>hdlKlikTabeledit(id,vItempot.value,vItempot.jenis)}
						className="classcursorpointer">
					({UFunc.formatAngka(vNilai)})
					</CLink>
				)}	
				</>	
				) : (<>({UFunc.formatAngka(vNilai)})</>)}
				</td>
				)
			})}

			<td className="px-1 text-end text-danger">
				({UFunc.formatAngka(vBPJSjumlah)})
			</td>

			<td className="px-1 text-end">{UFunc.formatAngka(vDiterima_1)}</td>
			<td className="px-1 text-end">{UFunc.formatAngka(pinjaman_lalu)}</td>

			{vPotlainArr.map((vItempot,vKeypot)=>{
				const vNilai	= //parseFloat(vItems["potongan_jml_"+vItempot.value]) *
					parseFloat(vItems["potongan_nsatuan_"+vItempot.value])

				return (
				<td key={vKeypot} className="px-1 text-end">
				{uDatamainObj.status_jurnal==="BELUM" ? (
				<>
				{parseInt(uTabeleditObj.id||0)===parseInt(id) 
					&& uTabeleditObj.jenis===vItempot.jenis
					&& parseInt(uTabeleditObj.id_caption)===parseInt(vItempot.value)
					? (
					<>
						{(is_tabelprocessing||false)===true ? (
							<CSpinner size="sm" color="primary"/>
						) : (
						<CInputGroup size="sm">
						<NumberFormat 
							value={uTabeleditObj.nsatuan}
							displayType={'text'} 
							thousandSeparator={"."} 
							decimalSeparator={","}
							onValueChange={(values) => uTabeleditObj.nsatuan=(values.value)}
							renderText={values => (
								<CFormInput size="sm"
									className="text-end"
									value={values}
									onChange={(e)=>{uTabeleditObj.nsatuan=e.target.value;setEfekview(!uEfekview)}}
									onKeyDown={(e)=>hdlKDownTabelinput(e)}
									onFocus={(e)=>e.target.select()}
								id={"inptabelinput"}/>
							)} 
							prefix=""/>
						<CButton size="sm" color="dark"
							onClick={()=>hdlKlikTabelinput()}
							id="btnnilaibpjs">
							<CIcon icon="cilCheckAlt"/>
						</CButton>
						</CInputGroup>
						)}
					</>
					) : (
					<CLink 
						onClick={()=>hdlKlikTabeledit(id,vItempot.value,vItempot.jenis)}
						className="classcursorpointer">
					({UFunc.formatAngka(vNilai)})
					</CLink>
				)}	
				</>	
				) : (<>({UFunc.formatAngka(vNilai)})</>)}
				</td>
				)
			})}
			<td className="px-1 text-end">{UFunc.formatAngka(vPinjamannow)}</td>
			<td className="px-1 text-end">
				<b className="text-success">{UFunc.formatAngka(vDiterima_2)}</b>
			</td>
			</tr>
			)
		})}
		</tbody>

		<tfoot>
			<tr className="align-top classfontsmaller fw-bolder">
			<td style={vStylekolfixed} className="bg-light">&nbsp;</td>
			<td style={vStylekolfixed} className="bg-light">JUMLAH</td>
			{uDatabtkArr.map((vItembtk,vKeybtk)=>{
				return (
				<>
				{(parseInt(vItembtk.value) === parseInt(uDatamainObj.gl_btk_umakan_id||0)) && (
					<>
					<td className="px-1 text-center">&middot;</td>
					<td className="px-1 text-end">&middot;</td>
					</>
				)}
				<td key={vKeybtk} className="px-1 text-end">
				{UFunc.formatAngka(uDatasumObj["sum_btk_"+vItembtk.value])}
				</td>
				</>
				)
			})}

			<td className="px-1 text-end">{UFunc.formatAngka(uDatasumObj.sum_btk_jumlah)}</td>
			
			{vPotbpjsArr.map((vItempot,vKeypot)=>{
				return (
				<td key={vKeypot} className="px-1 text-end text-danger">
				({UFunc.formatAngka(uDatasumObj["sum_bpjs_"+vItempot.value])})
				</td>
				)
			})}

			<td className="px-1 text-end text-danger">
				({UFunc.formatAngka(uDatasumObj.sum_bpjs_jumlah)})
			</td>

			<td className="px-1 text-end">
				{UFunc.formatAngka(uDatasumObj.sum_diterima_1)}
			</td>
			<td className="px-1 text-end">
				{UFunc.formatAngka(uDatasumObj.sum_pinjaman_lalu)}
			</td>
			{vPotlainArr.map((vItempot,vKeypot)=>{
				return (
				<td key={vKeypot} className="px-1 text-end text-danger">
				({UFunc.formatAngka(uDatasumObj["sum_lain_"+vItempot.value])})
				</td>
				)
			})}
			<td className="px-1 text-end">
				{UFunc.formatAngka(uDatasumObj.sum_pinjamannow)}
			</td>
			<td className="px-1 text-end text-success">
				{UFunc.formatAngka(uDatasumObj.sum_diterima_2)}
			</td>
			</tr>
		</tfoot>
		</table>
		</div>
		</CCardBody>
		</CCard>
		)//>
	}
	const contentHTMLcetak = () => {
		let vHTMLs	= uHTMLstyleTabel;

		const vDataviewArr	= uDatamainObj.items_arr || [];

		const vPotbpjsArr	= uDatapotonganArr.filter(vItems=>vItems.jenis==="POTONGAN BPJS"); 
		const vPotlainArr	= uDatapotonganArr.filter(vItems=>vItems.jenis==="POTONGAN LAIN");
		const gl_btk_umakan_id= parseInt(uDatamainObj.gl_btk_umakan_id) || 0;

		const vFilterBtkUmakanObj = (uDatabtkArr.filter(vItems=>
			parseInt(vItems.value) === parseInt(uDatamainObj.gl_btk_umakan_id||0))||[])[0];

		const vJmlKolnilai	= 
			(uDatabtkArr.length+1+2) +
			(vPotbpjsArr.length + 1) +
			(vPotlainArr.length + 2) +
			2;

		
		const vJudullaporan = "Data Payroll Karyawan";
		const vJudulsub		= `
			<div>Tgl Penggajian <b>`+UFunc.TglAngka(uDatamainObj.tgl_penggajian)+`</b></div>
			<div>Asal KAS <b>`+(uDatamainObj.gl_kas_caption||"UNDF Kas")+`</b></div>
		`;

		const vWidthno		= 50;
		const vWidthFirstkol= 175;
		const vWidthkolnilai= 90;
		const vWidthtabel	= vWidthno+vWidthFirstkol+(vWidthkolnilai * vJmlKolnilai);

		const vColspan 		= 2 + vJmlKolnilai;

		vHTMLs	+= UFunc.RenderTabel(uDataidentitasObj,vColspan);

		let vTabelheader	= `
			<tr>
			<th rowspan="2" width="`+vWidthno+`">No</th>
			<th rowspan="2" align="left">Nama Karyawan</th>
		`

		vTabelheader		+= uDatabtkArr.map((vItembtk,vKeybtk)=>{
			if(parseInt(vItembtk.value) === parseInt(uDatamainObj.gl_btk_umakan_id||0))
				return (
				`<th colspan="3">`+
				((vItembtk.nama_gl||"").length <= 12 ? (vItembtk.nama_gl||"Undf GL") : (vItembtk.nama_gl||"Undf GL").substr(0,12)+"..")+`
				</th>
				`);

			return (
			`<th rowSpan="2" width="`+vWidthkolnilai+`">`+
				((vItembtk.nama_gl||"").length <= 12 ? (vItembtk.nama_gl||"Undf GL") : (vItembtk.nama_gl||"Undf GL").substr(0,12)+"..")+`
			</th>`
			)
		}).join(``);

		vTabelheader	+= `
			<th rowspan="2" width="`+vWidthkolnilai+`">JML</th>
			<th colSpan="`+(vPotbpjsArr.length+1)+`">Pot.BPJS</th>
			<th rowSpan="2" width="`+vWidthkolnilai+`">Diterima (1)</th>
			<th rowSpan="2" width="`+vWidthkolnilai+`">Pinj.Bln Lalu</th>
		`;
		vTabelheader	+= vPotlainArr.map((vItems,vKeys)=>{
			return (
			`<th rowSpan="2" width="`+vWidthkolnilai+`">`+
			((vItems.caption_slip||"").length <= 12 ? (vItems.caption_slip||"Undf GL") : (vItems.caption_slip||"Undf GL").substr(0,12)+"..")+`
			</th>`
			)
		}).join(``);
		vTabelheader	+= `
			<th rowSpan="2" width="`+vWidthkolnilai+`">Pinj.Bln Ini</th>
			<th rowSpan="2" width="`+vWidthkolnilai+`">Diterima (2)</th>
			</tr>
		`;

		vTabelheader	+= `
			<tr valign="top">
			<th width="55">Hr.Kerja</th>
			<th width="`+vWidthkolnilai+`">N.Satuan</th>
			<th width="`+vWidthkolnilai+`">JML</th>
			`;

		vTabelheader	+= vPotbpjsArr.map((vItems,vKeys)=>{
			return (`
			<th width="`+vWidthkolnilai+`">`+UFunc.getWordnumber(vItems.caption_slip,2)+`</th>
			`)
		}).join(``);

		vTabelheader	+= `
			<th rowSpan="2" width="`+vWidthkolnilai+`">JML</th>
			</tr>
		`;

		let vTabelbody	= "";
		let vNumber		= 0;

		vTabelbody	+= vDataviewArr.map((vItems,vKeys)=>{
			const {
				pinjaman_lalu,is_tabelprocessing,
				nama_karyawan,id
			} = vItems;
			vNumber++;

			const vBTKJumlah 	= (uDatabtkArr.reduce((a,b)=>{ 
				return a + ( parseFloat(vItems["btk_jml_"+b.value]) * parseFloat(vItems["btk_nsatuan_"+b.value]) )
			},0) );	
			const vBPJSjumlah 	= (vPotbpjsArr.reduce((a,b)=>{ 
				return a + ( parseFloat(vItems["potongan_jml_"+b.value]) * parseFloat(vItems["potongan_nsatuan_"+b.value]) )
			},0) );
			const vPotlainjumlah= (vPotlainArr.reduce((a,b)=>{ 
				return a + ( parseFloat(vItems["potongan_jml_"+b.value]) * parseFloat(vItems["potongan_nsatuan_"+b.value]) )
			},0) );	

			const vDiterima_1 	= vBTKJumlah - vBPJSjumlah; 
			const vPinjamannow 	= parseFloat(pinjaman_lalu||0) - vPotlainjumlah;
			const vDiterima_2 	= vDiterima_1 - vPotlainjumlah;

			let vHTMLitem	=`
			<tr valign="top">
			<td align="right">`+vNumber+`.</td>
			<td align="left">`+nama_karyawan+`</td>
			`;

			vHTMLitem	+= uDatabtkArr.map((vItembtk,vKeybtk)=>{
				const vNilai	= //parseFloat(vItems["btk_jml_"+vItembtk.value]) *
					parseFloat(vItems["btk_nsatuan_"+vItembtk.value]);

				if(parseInt(vItembtk.value) === parseInt(uDatamainObj.gl_btk_umakan_id||0)) {
					const vJml	= parseFloat(vItems["btk_jml_"+vItembtk.value]);

					return (`
					<td align="center">`+UFunc.formatAngka(vJml)+`</td>

					<td align="right">`+UFunc.formatAngka(vNilai)+`</td>

					<td align="right">`+
						UFunc.formatAngka(parseInt(vItems["btk_jml_"+vItembtk.value]) * vNilai)+`
					</td>
					`)
				}


				return (`<td align="right">`+UFunc.formatAngka(vNilai)+`</td>`)
			}).join(``);

			vHTMLitem	+= `
			<td align="right">`+UFunc.formatAngka(vBTKJumlah)+`</td>
			`;

			vHTMLitem	+= vPotbpjsArr.map((vItempot,vKeypot)=>{
				const vNilai	= parseFloat(vItems["potongan_nsatuan_"+vItempot.value])
				
				return (`
				<td align="right">`+UFunc.formatAngka(vNilai)+`</td>
				`)
			}).join(``);

			vHTMLitem	+= `
			<td align="right">(`+UFunc.formatAngka(vBPJSjumlah)+`)</td>
			<td align="right">`+UFunc.formatAngka(vDiterima_1)+`</td>
			<td align="right">`+UFunc.formatAngka(pinjaman_lalu)+`</td>
			`;

			vHTMLitem	+= vPotlainArr.map((vItempot,vKeypot)=>{
				const vNilai	= parseFloat(vItems["potongan_nsatuan_"+vItempot.value])

				return (`
				<td align="right">`+UFunc.formatAngka(vNilai)+`</td>
				`)
			}).join(``);

			vHTMLitem	+= `
			<td align="right">`+UFunc.formatAngka(vPinjamannow)+`</td>
			<td align="right">
				<b>`+UFunc.formatAngka(vDiterima_2)+`</b>
			</td>
			</tr>
			`

			return vHTMLitem;
		}).join(``);

		let vTabelfooter 	= `
			<tr valign="top">
				<td align="center" colspan="2">JUMLAH</td>
			`;

		vTabelfooter 	+= uDatabtkArr.map((vItembtk,vKeybtk)=>{
			if(parseInt(vItembtk.value) === parseInt(uDatamainObj.gl_btk_umakan_id||0)) return (`
				<td align="center">&middot;</td>
				<td align="center">&middot;</td>
				<td align="right">`+UFunc.formatAngka(uDatasumObj["sum_btk_"+vItembtk.value])+`</td>
			`)

			return (`
				<td align="right">`+UFunc.formatAngka(uDatasumObj["sum_btk_"+vItembtk.value])+`</td>
			`)
		}).join(``);

		vTabelfooter 	+= `
			<td align="right">`+UFunc.formatAngka(uDatasumObj.sum_btk_jumlah)+`</td>
			`;

		vTabelfooter 	+= vPotbpjsArr.map((vItempot,vKeypot)=>{
			return (`
				<td align="right">
				(`+UFunc.formatAngka(uDatasumObj["sum_bpjs_"+vItempot.value])+`)
				</td>
			`)
		}).join(``);

		vTabelfooter 	+= `
			<td align="right">(`+UFunc.formatAngka(uDatasumObj.sum_bpjs_jumlah)+`)</td>
			<td align="right"><b>`+UFunc.formatAngka(uDatasumObj.sum_diterima_1)+`</b></td>
			<td align="right">`+UFunc.formatAngka(uDatasumObj.sum_pinjaman_lalu)+`</td>
		`;

		vTabelfooter 	+= vPotlainArr.map((vItempot,vKeypot)=>{
			return (`
				<td align="right">
				(`+UFunc.formatAngka(uDatasumObj["sum_lain_"+vItempot.value])+`)
				</td>
			`)
		}).join(``);

		vTabelfooter 	+= `
			<td align="right">`+UFunc.formatAngka(uDatasumObj.sum_pinjamannow)+`</td>
			<td align="right"><b>`+UFunc.formatAngka(uDatasumObj.sum_diterima_2)+`</b></td>
			</tr>
		`;

		vHTMLs 		= vHTMLs.replace(/_TITELSUB_/g,vJudulsub);
		vHTMLs 		= vHTMLs.replace(/_JUDULLAPORAN_/g,vJudullaporan);
		vHTMLs 		= vHTMLs.replace(/_TABELHEADER_/g,vTabelheader);
		vHTMLs 		= vHTMLs.replace(/_TABELBODY_/g,vTabelbody);
		vHTMLs 		= vHTMLs.replace(/_TABELFOOTER_/g,vTabelfooter);

		return vHTMLs;
	}
	//---END CONTENT--/

	//---REST_API--/
	const apiLoaddata = () => {
		setHTMLError500();
		setDatamainObj({});
		setLoading(true);

		/*//--TESTING_FRONTEND--/
		let vTmpObj	= {}
		let vTimeout = setTimeout(()=>{
			clearTimeout(vTimeout);
			setLoading(false);
			setDatamainObj(vTmpObj);
		},2500); return;
		//--END TESTING_FRONTEND--*/

		const vDATAS	= JSON.stringify({
			send_tabelid : uTabelID,
			send_tokenauth : uTokenObj.userinit
		});
		const vURLs	= pjson.homepage+"api/api_payroll/ld_formitem";

		const vTimeBegin = new Date();
		fetch(vURLs,{
			headers: { Accept: "application/json", "Content-Type": "application/json" },
			method: "POST",
			mode: "cors",
			cache: "default",
			body: vDATAS,
		}).then((response)=> { if (response.status === 200) { setSessionaktif(false); return response.json(); } })
		.then((output_string) => {
			setSessionaktif(true)
			setLoading(false);
			//console.log("(Payroll-apiLoaddata) output_string : "+JSON.stringify(output_string.errors));
			if(output_string.tampil) {
				setDataidentitasObj(JSON.parse(output_string.dataidentitas || "{}"));
				setDatabtkArr(JSON.parse(output_string.databtk || JSON.stringify(uDatabtkArr)));
				setDatapotonganArr(JSON.parse(output_string.datapotongan || JSON.stringify(uDatapotonganArr)));
				setDatamainObj(JSON.parse(output_string.dataobject || "{}"));
			} else if(output_string.info) {
				setHTMLError500(output_string.info);
			} else if(output_string.errors) {
				console.log("(Payroll-apiLoaddata) output_string.errors : "+output_string.errors);
				const vMsg 	= (pjson.mydefault.environment==="development")
					? output_string.errors : pjson.mydefault.msgFetchError;
				setHTMLError500("<font color='red'>"+vMsg+"</font>");
			} else if(output_string.expired) {
				setLoading(true); prosesExpired();
			}
		})
		.catch((error) =>{
			setLoading(false);
			console.log("(Payroll-apiLoaddata) catch-error: "+error);
			setHTMLError500(pjson.mydefault.msg500str.join(" "));
		});
	}
	const apiProsestanggal = () => {
		if(uDataeditObj.tgl_penggajian === uDatamainObj.tgl_penggajian 
			&& uDataeditObj.gl_kas_caption === uDatamainObj.gl_kas_caption) {
			hdlToggletanggal(); return;
		}

		uDatamainObj.is_tanggalprocessing = true;
		setEfekview(!uEfekview);

		/*let vTimeout = setTimeout(()=>{
			clearTimeout(vTimeout);
			uDatamainObj.is_tanggalprocessing = false;

			uDatamainObj.tgl_penggajian = uDataeditObj.tgl_penggajian;
			uDatamainObj.gl_kas_caption = uDataeditObj.gl_kas_caption;
			uDatamainObj.gl_kas_id 		= uDataeditObj.gl_kas_id;

			//console.log("(Payrollform - apiProsestabel) uTabeleditObj.jenis => "+uTabeleditObj.jenis) 
			hdlToggletanggal();
		},2500); return;//-*/

		const vDATAS	= JSON.stringify({
			send_tabelid : uTabelID,
			send_editjson : JSON.stringify(uDataeditObj),
			send_editjenis: "DATA",
			send_tokenauth : uTokenObj.userinit
		});
		const vURLs	= pjson.homepage+"api/api_payroll/pr_edit";

		fetch(vURLs,{
			headers: { Accept: "application/json", "Content-Type": "application/json" },
			method: "POST",
			mode: "cors",
			cache: "default",
			body: vDATAS,
		}).then((response)=> { if (response.status === 200) { setSessionaktif(false); return response.json(); } })
		.then((output_string) => {
			uDatamainObj.is_tanggalprocessing = false;
			setSessionaktif(true)
			//console.log("(Payroll-apiLoaddata) output_string : "+JSON.stringify(output_string.errors));
			if(output_string.sukses) {
				uDatamainObj.tgl_penggajian = uDataeditObj.tgl_penggajian;
				uDatamainObj.gl_kas_caption = uDataeditObj.gl_kas_caption;
				uDatamainObj.gl_kas_id 		= uDataeditObj.gl_kas_id;
				hdlToggletanggal();
			} else if(output_string.info) {
				showToast(output_string.info);
			} else if(output_string.errors) {
				console.log("(Payroll - apiProsessimpan) output_string.errors : "+output_string.errors);
				const vMsg 	= (pjson.mydefault.environment==="development")
					? output_string.errors : pjson.mydefault.msgFetchError;
				showToast(vMsg,"ERROR");
			} else if(output_string.expired) {
				setLoading(true); prosesExpired();
			}
		})
		.catch((error) =>{
			uDatamainObj.is_tanggalprocessing = false;
			console.log("(Payroll - apiProsessimpan) catch-error: "+error);
			showToast(pjson.mydefault.msg500str.join(" "),"ERROR");
		});
	}
	const apiProsestabel = () => {
		const vItemArr	= uDatamainObj.items_arr || [];
		const vArridx 	= UFunc.getObjectIndeks(vItemArr,uTabeleditObj.id);
		let vNsatuanbefore	= 0;
		let vJmlbefore		= 0;
		if(uTabeleditObj.jenis==="PENDAPATAN") {
			vJmlbefore		= parseFloat(vItemArr[vArridx]["btk_jml_"+uTabeleditObj.id_caption]);
			vNsatuanbefore	= parseFloat(vItemArr[vArridx]["btk_nsatuan_"+uTabeleditObj.id_caption]);
		} else{
			vJmlbefore		= parseFloat(vItemArr[vArridx]["potongan_jml_"+uTabeleditObj.id_caption]);
			vNsatuanbefore	= parseFloat(vItemArr[vArridx]["potongan_nsatuan_"+uTabeleditObj.id_caption]);
		}

		if((uTabeleditObj.is_jumlah||false)===true) {
			if(vJmlbefore === parseFloat(uTabeleditObj.jml || 0)) {
				setTabeleditObj({});setEfekview(!uEfekview);
				return;
			}
		} else {
			if(vNsatuanbefore === parseFloat(uTabeleditObj.nsatuan || 0)) {
				setTabeleditObj({});setEfekview(!uEfekview);
				return;
			}
		}
		vItemArr[vArridx].is_tabelprocessing = true;
		setEfekview(!uEfekview);

		/*let vTimeout = setTimeout(()=>{
			clearTimeout(vTimeout);
			vItemArr[vArridx].is_tabelprocessing = false;

			if((uTabeleditObj.is_jumlah||false)===true) {
				if(uTabeleditObj.jenis==="PENDAPATAN") {
					vItemArr[vArridx]["btk_jml_"+uTabeleditObj.id_caption] = parseInt(uTabeleditObj.jml);
				} else{
					vItemArr[vArridx]["potongan_jml_"+uTabeleditObj.id_caption] = parseInt(uTabeleditObj.jml);
				}
			} else {
				if(uTabeleditObj.jenis==="PENDAPATAN") {
					vItemArr[vArridx]["btk_nsatuan_"+uTabeleditObj.id_caption] = parseFloat(uTabeleditObj.nsatuan);
				} else{
					vItemArr[vArridx]["potongan_nsatuan_"+uTabeleditObj.id_caption] = parseFloat(uTabeleditObj.nsatuan);
				}
			}
			setTabeleditObj({});setEfekview(!uEfekview);
			prosesSUM();
		},2500); return;//-*/

		const vDATAS	= JSON.stringify({
			send_tabelid : uTabelID,
			send_editjson : JSON.stringify(uTabeleditObj),
			send_editjenis: "TABEL",
			send_tokenauth : uTokenObj.userinit
		});
		const vURLs	= pjson.homepage+"api/api_payroll/pr_edit";

		fetch(vURLs,{
			headers: { Accept: "application/json", "Content-Type": "application/json" },
			method: "POST",
			mode: "cors",
			cache: "default",
			body: vDATAS,
		}).then((response)=> { if (response.status === 200) { setSessionaktif(false); return response.json(); } })
		.then((output_string) => {
			vItemArr[vArridx].is_tabelprocessing = false;
			setSessionaktif(true)
			//console.log("(Payroll-apiLoaddata) output_string : "+JSON.stringify(output_string.errors));
			if(output_string.sukses) {
				if((uTabeleditObj.is_jumlah||false)===true) {
					if(uTabeleditObj.jenis==="PENDAPATAN") {
						vItemArr[vArridx]["btk_jml_"+uTabeleditObj.id_caption] = parseInt(uTabeleditObj.jml);
					} else{
						vItemArr[vArridx]["potongan_jml_"+uTabeleditObj.id_caption] = parseInt(uTabeleditObj.jml);
					}
				} else {
					if(uTabeleditObj.jenis==="PENDAPATAN") {
						vItemArr[vArridx]["btk_nsatuan_"+uTabeleditObj.id_caption] = parseFloat(uTabeleditObj.nsatuan);
					} else{
						vItemArr[vArridx]["potongan_nsatuan_"+uTabeleditObj.id_caption] = parseFloat(uTabeleditObj.nsatuan);
					}
				}
				setTabeleditObj({});
				prosesSUM();
			} else if(output_string.info) {
				showToast(output_string.info);
			} else if(output_string.errors) {
				console.log("(Payroll - apiProsessimpan) output_string.errors : "+output_string.errors);
				const vMsg 	= (pjson.mydefault.environment==="development")
					? output_string.errors : pjson.mydefault.msgFetchError;
				showToast(vMsg,"ERROR");
			} else if(output_string.expired) {
				setLoading(true); prosesExpired();
			}
		})
		.catch((error) =>{
			vItemArr[vArridx].is_tabelprocessing = false;
			console.log("(Payroll - apiProsessimpan) catch-error: "+error);
			showToast(pjson.mydefault.msg500str.join(" "),"ERROR");
		});
	}
	//---END REST_API--/

	//---EFFECT--/
	React.useEffect(()=>{})
	React.useEffect(()=>{
		uDispatch({type: "set", effectApp: !uEfekapp});
		uDispatch({type: "set", gUrlbefore: window.location.pathname + window.location.search});

    	apiLoaddata();

    	return () => {
    		setDatamainObj({});
    		setDatabtkArr([]);setDatapotonganArr([]);
    		setDataidentitasObj({});
    	}
	},[])
	React.useEffect(()=>{
		const vItemArr		= uDatamainObj.items_arr || [];

		vItemArr.map((vItems,vKeys)=>{
			uDatabtkArr.map((vItembtk,vKeybtk)=>{
				if(vItems["btk_jml_"+vItembtk.value] === undefined)
					if(parseInt(uTabeleditObj.id_caption) === parseInt(uDatamainObj.gl_btk_umakan_id)
						)
						vItems["btk_jml_"+vItembtk.value] = 0;
					else
						vItems["btk_jml_"+vItembtk.value] = 1;
				if(vItems["btk_nsatuan_"+vItembtk.value] === undefined)
					vItems["btk_nsatuan_"+vItembtk.value] = 0;
			});
			uDatapotonganArr.map((vItempot,vKeypot)=>{
				if(vItems["potongan_jml_"+vItempot.value] === undefined)
					vItems["potongan_jml_"+vItempot.value] = 1;
				if(vItems["potongan_nsatuan_"+vItempot.value] === undefined)
					vItems["potongan_nsatuan_"+vItempot.value] = 0;
			});
		});
		if(uDatamainObj.gl_kas_caption === undefined) {
			const vArridx = UFunc.getObjectIndeks(uDataglkasArr,uDatamainObj.gl_kas_id,"value");
			uDatamainObj.gl_kas_caption = vArridx >= 0 ? uDataglkasArr[vArridx].caption : undefined;
		}

		prosesSUM();
	},[uDatamainObj,
	uDatamainObj.gl_kas_caption,
	uDatamainObj.items_arr,
	(uDatamainObj.items_arr||[]).length,
	])
	React.useEffect(()=>{
		if((uDatamainObj.init_editing||"TIDAK") === "YA") {
			uDataeditObj.tgl_penggajian = uDatamainObj.tgl_penggajian
			uDataeditObj.gl_kas_caption = uDatamainObj.gl_kas_caption
			uDataeditObj.gl_kas_id = uDatamainObj.gl_kas_id
		} else {
			setDataeditObj({});
		}
	},[uDatamainObj,
	uDatamainObj.init_editing
	])
	React.useEffect(()=>{
		if(!uISDlglookupshow) { setDlglookupObj({}); return }
	},[uISDlglookupshow]);
	//---END EFFECT--/

	//console.log("(Payrollform) uNavigate => "+JSON.stringify(uNavigate))
	if(UFunc.isEmpty(uTokenObj)) { setLoading(true); prosesExpired(); return (<></>); }

	return (
		<>
		<div className="wrapper d-flex flex-column min-vh-100 bg-transparent">
		<CHeader position="sticky" className="mb-4 p-0">
		<CContainer fluid className="classheadermain" style={{minHeight:64}}>
			<CHeaderToggler className="px-0" onClick={()=>uNavigate(-1)}>
			<CIcon icon="cilArrowCircleLeft" className="me-2 align-middle" height={30}/>
			</CHeaderToggler>

			<CHeaderBrand className="me-auto">
				<small className="fw-bolder">{(uBahasaObj.caption_formpayroll||"Form Data Payroll").toUpperCase()}</small>
			</CHeaderBrand>

			<CHeaderNav className="">
				<CLink className="classcursorpointer classikon classikonreset"
					onClick={()=>hdlKlikReset()}/>
				<span className="d-none d-lg-none mx-1"/>
				<span className="d-none d-lg-inline mx-1 me-2">&middot;</span>
				<MyProfileDetil {...props}/>
			</CHeaderNav>
		</CContainer>
		</CHeader>

		<div className="body flex-grow-1 px-1">
		<CContainer xxl className="px-0 mx-auto d-none d-md-block">
		{uHTMLError500 ? (
			<MyPage500 content={uHTMLError500}/>
		) : (
		<>
			<CRow className="mx-0 align-items-stretch">
			<CCol md="5" className="p-0 mb-3">{contentTanggal()}</CCol>

			<CCol md="5" className="p-0 mb-3 px-md-2 px-lg-3">{contentStatus()}</CCol>
			
			<CCol md="2" className="p-0 mb-3">{contentAksi()}</CCol>
			</CRow>

			<CRow className="mx-0">
			<CCol className="p-0">{contentItem()}</CCol>
			</CRow>
		</>
		)}
		</CContainer>

		<CContainer className="px-0 mx-auto d-md-none">{contentMobileSM()}</CContainer>
		</div>
		</div>

		<MyDialoglookup
			show={uISDlglookupshow}
			toggle={()=>hdlDlglookuptoggle()}
			dialogHeader={uDlglookupObj.header}
			inputValue={uDlglookupObj.inputvalue||""}
			dataItemArr={uDlglookupObj.data_arr} 
			onKlikPilihItem={hdlKlikDlglookuppilih}
			id="iddialoglookup"/>

		<iframe id="ifmcontentstoprint" title="FrameCetak" className="d-none"/>
		</>
	)//>
}	

export default Payrollform